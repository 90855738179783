import {
  SET_CURRENT_BUSINESSES,
  SET_TIMEZONE,
  SET_AVAILABILITY_CUSTOM,
  SET_CURRENT_BUSINESSES_DISABLE,
  SET_CURRENT_BUSINESSES_ENABLE,
} from 'redux/actions/types';

const initialState = {
  currentBusiness: {},
  timeZone: [],
  availability: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_BUSINESSES:
      return { ...state, currentBusiness: payload };
    case SET_CURRENT_BUSINESSES_DISABLE:
      return {
        ...state,
        currentBusiness: {
          ...state.currentBusiness,
          disabled: payload.disabled,
        },
      };
    case SET_CURRENT_BUSINESSES_ENABLE:
      return {
        ...state,
        currentBusiness: {
          ...state.currentBusiness,
          disabled: payload.disabled,
        },
      };
    case SET_TIMEZONE:
      return { ...state, timeZone: payload };
    case SET_AVAILABILITY_CUSTOM:
      return { ...state, availability: payload };

    default:
      return state;
  }
};
