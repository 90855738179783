import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import moment from 'moment-timezone';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { DialogTitle, Dialog, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { updateMenuProduct, deleteMenuProduct, addMenuProduct } from 'redux/actions/Menu/menu';
import { getProductsAll, getProductById, getCategoryProductsV2 } from 'redux/actions/Menu/products';
import { groupModifier, deleteModifier, getModifiersAll } from 'redux/actions/Menu/modifiers';
import { TabsList, StyledTab } from './styled';
import ProductFormBasic from 'components/MenuNew/FormView/ProductFormBasic';
import ProductFormDetails from 'components/MenuNew/FormView/ProductFormDetails';
import FormHeader from 'components/MenuNew/FormView/FormHeader';
import Preloader from 'components/Preloader';
import { confirm } from 'components/MenuNew/ConfirmModal';
import UpdateAvailability from './UpdateAvailability/UpdateAvailability';
import { SET_LOADING } from 'redux/actions/types';
import Private from 'components/Private';
import { SOLD_OUT_STATUS } from 'constants/enumSoldOut';
import { MENU_EDIT } from 'constants/permissions';
import useQuery from 'hooks/useQuery';
import usePermission from 'hooks/permission';
import ModifierGroupForm from '../NewModifierGroup/index';
import { MENU_TYPES, CONTEXT_TYPES } from 'constants/menu';
import ModifierOptions from './ModifierOption/index';
import FormFooter from './FormFooter';
import { getMenusV2 } from 'redux/actions/Menu/menuV2';
import { priceTransform } from 'helpers/formPrice';

function BasePriceConfirmDialog(props) {
  const { onClose, setConfirmed, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Hello</DialogTitle>
      <Button onClick={() => setConfirmed(true)}>Yes</Button>
      <Button onClick={() => setConfirmed(false)}>Cancel</Button>
    </Dialog>
  );
}

function TabPanel(props) {
  const { value, index, content } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ py: 3 }}>{content}</Box>}
    </div>
  );
}

const initialData = {
  name: '',
  price: '',
  refs: [],
  websitePrice: '',
  image: {
    url: '',
    imageId: '',
  },
  description: '',
  carbs: '',
  sort: 0,
  tax: '',
  calories: '',
  proteins: '',
  priceOverride: [{ context_type: CONTEXT_TYPES.provider, context_value: MENU_TYPES.main, price: 0 }],
  modifiers: [],
  isActive: true,
  onlyUsedAsModifer: false,
  sizeTypeModifiers: [],
  isOnePrice: true,
  connectedProviders: { main: true },
  category: '',
  classifications: [],
  soldOut: {
    status: SOLD_OUT_STATUS.AVAILABILITY,
    soldOutDate: '',
  },
  onItsOwn: true,
};

const productFormInfo = yup.object().shape({
  name: yup.string().required('This field is required'),

  price: yup.lazy((value) =>
    value === ''
      ? yup.string().required('This field is required')
      : yup
          .number()
          .min(0, 'Price must be greater than or equal to 0')
          .transform((value) => (isNaN(value) ? null : value))
          .typeError('This field is required')
          .required('This field is required')
          .test('sizeTypeModifiers', "Price can't exceed $375.00 for Ubereats", (price, option) => {
            let tested = true;
            if (option.parent.priceOverride) {
              option.parent.priceOverride.map((item) => {
                if (item.context_value === MENU_TYPES.ubereats) {
                  if (option.parent.isOnePrice) {
                    if (option.parent.connectedProviders[MENU_TYPES.ubereats] && price > 374 && !item.price) {
                      tested = false;
                    } else {
                      tested = true;
                    }
                  }
                }
              });
              return tested;
            }
          })
  ),
  tax: yup
    .number()
    .min(null, 'Price must be greater than or equal to 0')
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(),
  description: yup.string().nullable(),
  priceOverride: yup.array(
    yup.object().shape({
      price: yup.lazy((value) =>
        value === ''
          ? yup.string().required('This field is required')
          : yup
              .number()
              .min(0, 'Price must be greater than or equal to 0')
              .transform((value) => (isNaN(value) ? null : value))
              .typeError('This field is required')
              .required('This field is required')
              .test('sizeTypeModifiers', "Price can't exceed $375.00 for Ubereats", (price, option) => {
                const params = new URLSearchParams(document.location.search);
                const pid = params.get('pid');
                const isSizeType = option.from.filter((item) => item.value?.isOnePrice)?.find((item) => item);
                const connectedProviders = option.from.map((item) => item.value).find((item) => item._id === pid)
                  ?.connectedProviders;
                if (!connectedProviders) {
                  return true;
                }
                if (
                  !isSizeType?.value.isOnePrice &&
                  option.parent.context_value === MENU_TYPES.ubereats &&
                  connectedProviders[MENU_TYPES.ubereats]
                ) {
                  return true;
                } else {
                  return option.parent.context_value === MENU_TYPES.ubereats &&
                    connectedProviders[MENU_TYPES.ubereats] &&
                    price > 374
                    ? false
                    : true;
                }
              })
      ),
    })
  ),
  sizeTypeModifiers: yup.array(
    yup.object().shape({
      list: yup.array(
        yup.object().shape({
          name: yup.string().required('This field is required'),
          optionSort: yup.number().required('This field is required'),
          price: yup.lazy((value) =>
            value === ''
              ? yup.string().required('This field is required')
              : yup
                  .number()
                  .min(0, 'Price must be greater than or equal to 0')
                  .transform((value) => (isNaN(value) ? null : value))
                  .typeError('This field is required')
                  .required('This field is required')
                  .test('list', "Price can't exceed $375.00 for Ubereats", (price, option) => {
                    const params = new URLSearchParams(document.location.search);
                    const pid = params.get('pid');
                    if (option.from) {
                      const connectedProviders = option.from.map((item) => item.value).find((item) => item._id === pid)
                        ?.connectedProviders;
                      if (!connectedProviders) {
                        return true;
                      }
                      let tested = true;
                      if (option.parent.priceOverride) {
                        option.parent.priceOverride.map((item) => {
                          if (item.context_value === MENU_TYPES.ubereats) {
                            if (connectedProviders[MENU_TYPES.ubereats] && price > 374 && !item.price) {
                              tested = false;
                            } else {
                              tested = true;
                            }
                          }
                        });
                        return tested;
                      }
                    }
                  })
          ),
          priceOverride: yup.array(
            yup.object().shape({
              price: yup.lazy((value) =>
                value === ''
                  ? yup.string().required('This field is required')
                  : yup
                      .number()
                      .min(0, 'Price must be greater than or equal to 0')
                      .transform((value) => (isNaN(value) ? null : value))
                      .typeError('This field is required')
                      .required('This field is required')
                      .test('sizeTypeModifiers', "Price can't exceed $375.00 for Ubereats", (price, option) => {
                        return option.parent.context_value === MENU_TYPES.ubereats && price > 374 ? false : true;
                      })
              ),
            })
          ),
        })
      ),
    })
  ),
  proteins: yup
    .number()
    .min(0, 'Proteins must be greater than or equal to 0')
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(),
  carbs: yup
    .number()
    .min(0, 'Carbs must be greater than or equal to 0')
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(),
  calories: yup
    .number()
    .min(0, 'Calories must be greater than or equal to 0')
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(),
});

const ProductForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const checkPermission = usePermission();
  const isInOverview = history.location.pathname.includes('overview');
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [basePriceConfirmDialogOpen, handleBasePriceConfirmDialogOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [productIsModOption, handleProductIsModOption] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentModifier, setCurrentModifier] = useState(null);
  const [currentModifierName, setCurrentModifierName] = useState(currentModifier?.name);
  const { menu, formProduct } = useSelector(({ menu }) => menu);
  const userData = useSelector(({ user: { data } }) => ({
    data,
  }));
  const { currentBusiness } = useSelector(({ business }) => business);
  const [deleteSizeTypeItem, setDeleteSizeTypeItem] = useState([]);
  // const [prompt, setDirty, setPristine] = useModifyForm();

  const readOnly = useMemo(() => {
    return !checkPermission(MENU_EDIT);
  }, [checkPermission, menu]);

  // const priceWatcher = useWatch('price');
  const productFormMethods = useForm({
    defaultValues: initialData,
    mode: 'all',
    resolver: yupResolver(productFormInfo),
  });

  const { reset, getValues, watch, formState, setValue, control, trigger, register } = productFormMethods;

  const sizeTypeModifierMethods = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'sizeTypeModifiers', // unique name for your Field Array
  });

  const productPriceOverride = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'priceOverride', // unique name for your Field Array
  });

  const sizeTypeModifierListMethods = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'sizeTypeModifiers[0].list', // unique name for your Field Array
  });

  const deletedOptionIds = useFieldArray({
    control,
    name: 'sizeTypeModifiers[0].deletedOptionIds',
  });

  const modifierGroupMethods = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'modifiers', // unique name for your Field Array
  });

  const [title, setTitle] = useState(getValues().name);
  // const [sizeTypeModifier, handleSizeTypeModifier] = useState(getValues().sizeTypeModifier);

  const query = useQuery();
  const productId = query.get('pid');
  const cid = query.get('cid');
  const modifierId = query.get('mid');
  const mode = query.get('mode');
  const entityType = query.get('entityType');
  const showModifier = modifierId && entityType === 'modifier';
  const [isOpen, setIsOpen] = useState(false);
  const [getImageValue, setImageGetvalue] = useState('');
  const validateImage = getValues()?.image?.url === currentProduct?.image?.url;
  const providerPriceOverride = [];
  const isEdit = mode === 'edit';
  const [disable, setDisable] = useState(false);

  const handleClickOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseBasePriceConfirmModal = (value) => {
    handleBasePriceConfirmDialogOpen(value);
  };

  const getProduct = () => {
    if (mode === 'edit') {
      setLoading(true);
      dispatch(getProductById(productId, cid)).finally(() => {
        setTabValue(0);
        setLoading(false);
      });
    } else {
      reset(initialData);
    }
  };

  useEffect(() => {
    getProduct();
  }, [productId]);

  useEffect(() => {
    reset(formProduct, { keepDirtyValues: false, keepDirty: false });
    setCurrentProduct(formProduct);
  }, [formProduct]);

  // useEffect(() => {
  //   if (!getValues().soldOut && mode === 'edit') {
  //     reset(currentProduct);
  //   }
  // }, [availableValue]);

  useEffect(() => {
    const categoryData = menu?.categories?.find((item) => item?._id === cid);
    if (isInOverview && categoryData) {
      Object.keys(categoryData.connectedProviders).map((modItem) => {
        if (categoryData.connectedProviders[modItem]) {
          providerPriceOverride.push({
            context_type: CONTEXT_TYPES.provider,
            context_value: modItem,
            name: modItem,
            price: 0,
          });
        }
      });
      productPriceOverride.replace(providerPriceOverride);
      setCurrentCategory(categoryData);
      if (!modifierId) {
        if (currentProduct && mode !== 'create') {
          // if (getValues()._id === productId) {
          //   reset(currentProduct);
          // }
        } else {
          if (isInOverview) {
            reset({ ...initialData, category: cid, priceOverride: providerPriceOverride });
          } else {
            reset({ ...initialData, priceOverride: providerPriceOverride });
          }
        }
      }
    }
  }, [mode, menu.categories, menu.productsAll, menu.connectedProviders, menu.bulkInfo, productId, cid]);

  useEffect(() => {
    if (mode !== 'edit' && !modifierId) {
      return setTabValue(0);
    }
    return setTabValue(tabValue);
  }, [productId, mode, cid, tabValue]);

  useEffect(() => {
    if (currentProduct?.isOnePrice) {
      dispatch(getModifiersAll(currentProduct?.isOnePrice));
    }
  }, [currentProduct?.isOnePrice]);

  useEffect(() => {
    if (sizeTypeModifierListMethods.fields.length === 1) {
      reset(
        { ...getValues(), isOnePrice: true, price: getValues('price') || '', sizeTypeModifiers: [] },
        { keepDirtyValues: true, keepDirty: true }
      );
    } else {
      setValue('price', getValues('price') || 0);
    }
  }, [sizeTypeModifierListMethods.fields]);

  useEffect(() => {
    const defaultBusiness = userData.data?.business;
    if (defaultBusiness._id !== currentBusiness._id && Object.keys(formState.touchedFields).length) {
      handleSaveData();
    }
  }, [currentBusiness]);

  const handleValidSubmit = useCallback(
    async (values) => {
      // handleSaveModifierGroup();
      if (values.sizeTypeModifiers[0]) {
        values.sizeTypeModifiers[0].deletedOptionIds = deletedOptionIds.fields.map((item) => item.id);
        values.sizeTypeModifiers[0].list.forEach((item, index) => (item.optionSort = index));
      }

      //Setting SortOption value as ordered in array
      if (isEdit) {
        dispatch({ type: SET_LOADING, payload: true });
        setLoading(true);
        return dispatch(updateMenuProduct(productId, { category: currentCategory?._id, ...values }))
          .then((res) => {
            setCurrentProduct(res);
            reset(res);
          })
          .finally(() => {
            setImageGetvalue('');
            setLoading(false);
            dispatch({ type: SET_LOADING, payload: false });
          });
      } else {
        setLoading(true);
        return dispatch(addMenuProduct({ ...values }))
          .then((res) => {
            history.push(`${history.location.pathname}`);
            setCurrentProduct(res);
            reset(res);
          })
          .finally(() => {
            setImageGetvalue('');
            setLoading(false);
            dispatch({ type: SET_LOADING, payload: false });
          });
      }
    },
    [cid, dispatch, productId, mode, deletedOptionIds.fields.length]
  );

  const changeProductTab = async (_, tab) => {
    const result = await trigger(['name', 'description', 'price', 'sizeTypeModifiers[0].list']);
    if (!result) return;
    const fieldsEdited = Object.values(formState.dirtyFields).length;

    // check your dev console, it's a Set
    if (
      (tab != tabValue && fieldsEdited && fieldsEdited && formState.touchedFields && formState.isDirty) ||
      (tab != tabValue && !validateImage && fieldsEdited && formState.touchedFields)
    ) {
      const confirmed = await confirm({
        title: '',
        message: `Are you sure you want to cancel the changes before leaving?`,
        cancelText: 'Cancel',
        confirmText: 'Save and Leave',
        confirmColor: 'primary',
        hideCloseIcon: false,
        onClose: () => {},
      });
      if (confirmed && !Object.keys(formState.errors).length) {
        if (tabValue === 1) {
          handleSaveModifierGroup();
          setTabValue(tab);
        } else {
          handleValidSubmit(getValues()).then((res) => {
            setTabValue(tab);
            reset(res);
            setImageGetvalue('');
          });
        }
      } else {
        setTabValue(tab);
        reset(getValues());
      }
    } else {
      setTabValue(tab);
    }
  };

  const changeIsOnePrice = (val = false) => {
    setValue('isOnePrice', val, { shouldValidate: true });
    if (!val && Object.keys(productFormMethods.formState.errors).includes('priceOverride')) {
      productFormMethods.clearErrors(productFormMethods.formState.name);
    }
    // const productBasePrice = getValues('price');
    if (!sizeTypeModifierListMethods.fields.length) {
      if (!sizeTypeModifierMethods.fields.length) {
        sizeTypeModifierMethods.insert(0, {
          id: uuidv4(),
        });
        let targetConnectedProviders;
        if (currentCategory) {
          targetConnectedProviders = currentCategory.connectedProviders;
        } else {
          targetConnectedProviders = menu.connectedProviders;
        }
        let providerPriceOverride = [];
        Object.keys(targetConnectedProviders).map((modItem) => {
          if (targetConnectedProviders[modItem]) {
            providerPriceOverride.push({
              context_type: CONTEXT_TYPES.provider,
              context_value: modItem,
              name: modItem,
              price: 0,
            });
          }
        });
        sizeTypeModifierListMethods.insert(0, [
          { id: uuidv4(), name: '', priceOverride: providerPriceOverride, price: 0, optionSort: 0 },
          { id: uuidv4(), name: '', priceOverride: providerPriceOverride, price: 0, optionSort: 1 },
        ]);
      }
    }
  };

  const handleAddModifierOption = () => {
    let connectedProviders;
    if (mode === 'edit') {
      connectedProviders = currentProduct?.connectedProviders;
    } else {
      connectedProviders = currentCategory?.connectedProviders;
    }

    sizeTypeModifierListMethods.append({
      name: '',
      priceOverride: [],
      price: 0,
      optionSort: sizeTypeModifierListMethods.fields.length,
    });
  };

  const handleDeleteModifierOption = (handleAddModifierOptionIndex, id) => {
    sizeTypeModifierListMethods.remove(handleAddModifierOptionIndex);
    if (id) {
      deletedOptionIds.append([{ id }]);
    }
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    // const newList = [...sizeTypeModifierListMethods.fields];
    // newList.forEach((item, index) => {});
    // sizeTypeModifierListMethods.update(oldIndex, {
    //   ...sizeTypeModifierListMethods.fields[oldIndex],
    //   optionSort: newIndex,
    // });
    // sizeTypeModifierListMethods.update(newIndex, {
    //   ...sizeTypeModifierListMethods.fields[newIndex],
    //   optionSort: oldIndex,
    // });
    if (oldIndex !== newIndex) {
      sizeTypeModifierListMethods.move(oldIndex, newIndex);
    }
  };

  const handleSaveData = async () => {
    const confirmed = await confirm({
      title: '',
      message: `Are you sure you want to cancel the changes before leaving?`,
      cancelText: 'Cancel',
      confirmText: 'Save and Leave',
      hideCloseIcon: true,
    });
    if (!confirmed) {
      handleValidSubmit(getValues());
      // document.getElementById('productSaveButton').click();
    } else {
      window.location.reload();
    }
  };

  const handleDelete = async () => {
    if (currentProduct.modifierId) {
      return handleProductIsModOption(true);
    }
    const target = entityType === 'item' ? 'item' : entityType === 'category' ? 'category' : 'modifier group';
    const confirmed = await confirm({
      title: `Are you sure you want to delete the ${target}?`,
      message: '',
      cancelText: 'Cancel',
      confirmText: 'Yes, delete',
      confirmColor: 'primary',
    });
    if (confirmed && mode == 'edit') {
      setLoading(true);
      if (entityType === 'item') {
        dispatch(deleteMenuProduct(productId))
          .then(() => {
            history.push(history.location.pathname);
            dispatch(getMenusV2()).then((res) => {
              res.categories.forEach((item) => {
                dispatch(getCategoryProductsV2(item._id)).finally(() => {
                  dispatch({ type: SET_LOADING, payload: false });
                });
              });
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(deleteModifier(modifierId))
          .then(() => {
            const indexOf = modifierGroupMethods.fields.findIndex((item) => item._id === modifierId);
            if (indexOf != -1) {
              modifierGroupMethods.remove(indexOf);
            }
            if (currentCategory?._id) {
              history.push({
                pathname: history.location.pathname,
                search: `?entityType=item&pid=${productId}&cid=${currentCategory?._id}&mode=edit`,
              });
            } else {
              history.push({
                pathname: history.location.pathname,
                search: `?entityType=item&pid=${productId}&cid=${uuidv4()}&mode=edit`,
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleSaveModifierGroup = (delId = '') => {
    setLoading(true);
    const modIds = delId.length ? delId : getValues().modifiers.map((item) => item._id);
    groupModifier(modIds, productId)
      .then(() => {
        if (currentCategory?._id || cid) {
          dispatch(getProductById(currentProduct?._id, currentCategory?._id || cid)).then((res) => {
            reset(res);
          });
          // dispatch(getCategoryProductsV2(currentCategory?._id));
        } else {
          dispatch(getProductsAll());
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResetPriceProvider = () => {
    productPriceOverride.fields.map((item, index) => {
      productPriceOverride.update(index, { ...item, price: currentProduct.price });
    });
  };
  const handleCleanPriceProvider = () => {
    productPriceOverride.fields.map((item, index) => {
      const target = currentProduct.priceOverride.find((targetItem) => item.context_value === targetItem.context_value);
      let price;
      if (target?.price || target?.price === 0) {
        price = target?.price;
      } else {
        price = item?.price;
      }
      productPriceOverride.update(index, { ...item, price });
    });
  };

  useEffect(() => {
    setTitle(getValues().name);
  }, [formState]);

  const titleModifier = (title) => {
    setCurrentModifierName(title);
  };

  const handleCloseDialog = (val) => {
    if (typeof val !== 'boolean') {
      return;
    }
    return handleProductIsModOption(val);
  };

  const connectedProvidersByTarget = useMemo(() => {
    if (mode === 'edit') {
      if (currentProduct?.modifierId) {
        return menu.connectedProviders;
      } else {
        return formProduct.connectedProviders;
      }
    }
    if (formProduct.modifierId) {
      return menu.connectedProviders;
    }
    if (formProduct.category) {
      return currentCategory?.connectedProviders;
    }

    return formProduct.connectedProviders;
  }, [formProduct, menu]);

  const onChangePrice = (e) => {
    const { name, value } = e.target;
    const findlValue = priceTransform(value);
    if (name.includes('priceOverride') && currentCategory.connectedProviders[MENU_TYPES.ubereats]) {
      if (findlValue) {
        productFormMethods.clearErrors('price');
      }
    }
    if (name.includes('sizeTypeModifiers') && currentCategory.connectedProviders[MENU_TYPES.ubereats]) {
      if (name.includes('priceOverride') && findlValue) {
        productFormMethods.clearErrors(!name.includes('priceOverride'));
      }
    }
    setValue(name, findlValue);
  };

  return (
    <Box sx={{ height: '100%', position: 'relative', overflow: 'hidden' }}>
      {/* Fixed Header */}
      {loading && <Preloader overlay={true} />}
      <Box>
        <FormHeader
          title={title}
          currentModifierName={currentModifierName}
          setCurrentModifierName={setCurrentModifierName}
          // currentModifierName={currentModifierName}
          currentProduct={currentProduct}
          cid={cid}
          data={getValues()}
          formProduct={formProduct}
          entityType={entityType}
          product={currentProduct}
          category={currentCategory}
          mode={mode}
          modifier={showModifier}
          handleDelete={handleDelete}
          menu={menu}
          connectedProviders={connectedProvidersByTarget}
        />
      </Box>
      {showModifier ? (
        <ModifierGroupForm
          setTitleModifier={titleModifier}
          modifierMethods={modifierGroupMethods}
          currentModifier={currentModifier}
          currentProduct={currentProduct}
          setTabValue={changeProductTab}
          setCurrentModifier={setCurrentModifier}
        />
      ) : (
        <FormProvider {...productFormMethods} sx={{ height: '100%' }} component="div">
          {isEdit && (
            <UpdateAvailability
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              availability={getValues()}
              currentBusiness={currentBusiness}
              dispatch={dispatch}
              register={register}
              formProduct={formProduct}
              title="item"
            />
          )}
          <form style={{ height: '100%' }} onSubmit={productFormMethods.handleSubmit(handleValidSubmit, (err) => {})}>
            {/* Scrolled content */}
            <Box sx={{ height: 'calc(100% - 176px)', overflowY: 'auto', px: 4, pb: 3, pt: 2 }}>
              <TabsList onChange={changeProductTab} value={tabValue} variant="scrollable" scrollButtons="auto">
                <StyledTab label="Basic" />
                <StyledTab label="Modifiers / Options" />
                <StyledTab label="Advanced" />
              </TabsList>

              <TabPanel
                value={tabValue}
                index={0}
                content={
                  <Private permission="MENU_EDIT" disabled={true}>
                    <ProductFormBasic
                      imageGetvalue={setImageGetvalue}
                      isInOverview={isInOverview}
                      changeIsOnePrice={changeIsOnePrice}
                      readOnly={readOnly}
                      mode={mode}
                      onChangePrice={onChangePrice}
                      connectedProvidersByTarget={connectedProvidersByTarget}
                      handleClickOpen={handleClickOpen}
                      data={getValues()}
                      currentProductSizeTypeModifiers={currentProduct?.sizeTypeModifiers}
                      productPriceOverride={productPriceOverride.fields}
                      productFormMethods={productFormMethods}
                      handleAddModifierOption={handleAddModifierOption}
                      handleDeleteModifierOption={handleDeleteModifierOption}
                      sizeTypeModifierFields={sizeTypeModifierMethods.fields}
                      sizeTypeModifierListFields={sizeTypeModifierListMethods.fields}
                      handleSortEnd={handleSortEnd}
                      control={control}
                      handleResetPriceProvider={handleResetPriceProvider}
                      handleCleanPriceProvider={handleCleanPriceProvider}
                      currentCategory={currentCategory}
                      menu={menu}
                      currentProduct={currentProduct}
                      loading={loading}
                      initialData={initialData}
                      setDisable={setDisable}
                    />
                  </Private>
                }
              />
              <TabPanel
                value={tabValue}
                index={1}
                content={
                  <ModifierOptions
                    cid={cid}
                    handleSaveModifierGroup={handleSaveModifierGroup}
                    // navigateToModifierForm={navigateToModifierForm}
                    currentProduct={currentProduct}
                    currentCategory={currentCategory}
                    modifierGroupMethods={modifierGroupMethods}
                  />
                }
              />
              <TabPanel
                value={tabValue}
                index={2}
                content={
                  <Private permission="MENU_EDIT" disabled={true}>
                    <ProductFormDetails data={getValues()} />
                  </Private>
                }
              />
            </Box>
            {/* Fixed Footer */}

            <Box>
              <FormFooter
                isDisable={formState.isDirty || !validateImage}
                disabled={disable}
                mode={mode}
                handleSaveModifierGroup={handleSaveModifierGroup}
                tabValue={tabValue}
                isEdit={isEdit}
                handleClickOpen={handleClickOpen}
              />
            </Box>
          </form>
        </FormProvider>
      )}
      <Dialog onClose={handleCloseDialog} open={productIsModOption} PaperProps={{ sx: { p: 3 } }}>
        <IconButton
          sx={{ position: 'absolute', top: 0, right: 0 }}
          aria-label="close"
          onClick={() => handleCloseDialog(false)}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ textAlign: 'center' }}>
          You can not delete this product as it is used as a modifier option.
        </DialogTitle>
        {/* <Typography sx={{ fontSize: '14px', fontWeight: 500, textAlign: 'center', color: '#3D4350', mt: 1 }}>
          You can not delete this product as it is used as a modifier option.
        </Typography> */}
      </Dialog>
      <BasePriceConfirmDialog open={basePriceConfirmDialogOpen} onClose={handleCloseBasePriceConfirmModal} />
    </Box>
  );
};

export default ProductForm;
