import React, { useState, useEffect } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import useQuery from 'hooks/useQuery';
import { v4 as uuidv4 } from 'uuid';
import {
  Paper,
  Box,
  Grid,
  InputAdornment,
  Typography,
  TextField,
  Button,
  List,
  Divider,
  Stack,
  ListItemButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DoneIcon from '@mui/icons-material/Done';
import Private from 'components/Private';
import { getModifiersAll, reorderModifiers } from 'redux/actions/Menu/modifiers';
import SortableModiferContainerList from 'components/MenuNew/FormView/ModifierFormGroup/SortableModiferContainerList';

const ModifierOptions = ({ modifierGroupMethods, currentProduct, cid, handleSaveModifierGroup = () => {} }) => {
  const history = useHistory();
  const query = useQuery();
  const productId = query.get('pid');
  const categoryId = query.get('cid');
  const [searchInputValue, handleSearchInputValue] = useState('');
  const [showModifierList, handleShowModifierList] = useState('');
  const { menu } = useSelector(({ menu }) => menu);
  const [loadingModifierOption, handleLoadingModifierOption] = useState(false);
  const { modifiersAll } = menu;
  const [modifiers, setModifiers] = useState(modifiersAll);
  const { fields, remove } = modifierGroupMethods;
  const [modifierList, setModifierList] = useState(fields);
  const dispatch = useDispatch();
  useEffect(() => {
    // return handleLoadingModifierOption(false);
    dispatch(getModifiersAll(currentProduct?.isOnePrice));
  }, []);

  const onChange = ({ target }) => {
    handleSearchInputValue(target.value);
    if (!target.value) {
      setModifiers(modifiersAll);
    } else {
      setModifiers((prev) => {
        const newList = modifiersAll.filter((item) => item.name.toLowerCase().includes(target.value.toLowerCase()));
        return newList;
      });
    }
  };

  const addModifier = (data) => {
    const found = fields.find((item) => item._id === data._id);
    if (!found) {
      navigateToModifierForm(data);
    }
  };

  const handleCreateModifierGroup = () => {
    navigateToModifierForm();
    history.push({
      search: `?entityType=modifier&pid=${productId}&cid=${categoryId}&mid=${uuidv4()}&mode=create`,
    });
  };

  const handleDeleteModifier = (item) => {
    modifierList.forEach((modItem, index) => {
      if (modItem._id === item._id) {
        remove(index);
        modifierList.splice(index, 1);
        setModifierList([...fields]);
      }
    });
  };

  const handleAdd = () => {
    handleShowModifierList(true);
    dispatch(getModifiersAll(currentProduct?.isOnePrice));
    setModifiers(modifiersAll);
  };

  const handleEditModifier = (item) => {
    handleLoadingModifierOption(true);
    setTimeout(() => {
      navigateToModifierForm(item);
    }, 100);
  };
  const navigateToModifierForm = (modifier = null) => {
    if (modifier) {
      history.push({
        search: `?entityType=modifier&pid=${productId}&cid=${cid}&mid=${modifier._id}&mode=edit`,
        // state: modifier,
      });
    } else {
      history.push({
        search: `?entityType=modifier&pid=${productId}&cid=${cid}&mid=${uuidv4()}&mode=create`,
      });
    }
  };

  const handleOnSort = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      modifierGroupMethods.move(oldIndex, newIndex);
      setModifierList(arrayMove(modifierList, oldIndex, newIndex));
      dispatch(
        reorderModifiers(arrayMove(modifierList, oldIndex, newIndex), currentProduct.category, currentProduct._id)
      );
      // handleSaveModifierGroup();
    }
  };
  return (
    <Box>
      <Grid container alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={'auto'}>
          <Typography sx={{ color: '#3D4350', fontWeight: 500 }}>Modifier Groups</Typography>
        </Grid>
        <Grid item xs sx={{ ml: 3 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} flexDirection="column">
          <Stack flexWrap="wrap" direction="row" sx={{ my: 1 }}>
            <SortableModiferContainerList
              onSortEnd={handleOnSort}
              items={modifierList}
              useDragHandle
              handleDeleteModifier={handleDeleteModifier}
              handleEditModifier={handleEditModifier}
              loadingModifierOption={loadingModifierOption}
            />
          </Stack>
          <Private permission="MENU_EDIT">
            <Button
              disabled={currentProduct?.modifierId}
              onClick={handleAdd}
              sx={{ color: 'black' }}
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Private>
        </Grid>
      </Grid>
      {showModifierList && (
        <Paper>
          <Box>
            <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
              <Typography sx={{ color: '#9E9EAD', fontSize: '14px', mb: 1 }}>Select Modifier Group</Typography>
              {modifiersAll ? (
                <TextField
                  hiddenLabel
                  sx={{ width: '100%', border: 'none' }}
                  variant="filled"
                  value={searchInputValue}
                  onChange={onChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
              ) : null}
            </Box>

            <Box sx={{ maxHeight: '249px', overflowY: 'auto', position: 'relative' }}>
              {modifiersAll ? (
                <List sx={{ pt: 0 }}>
                  {modifiers?.map((item) => {
                    const isDisabled = !!fields?.find((items) => items._id === item._id);
                    return (
                      <ListItemButton
                        disabled={isDisabled}
                        key={item._id}
                        onClick={() => {
                          addModifier(item);
                        }}
                        sx={{
                          borderTop: 1,
                          borderColor: (theme) => theme.palette.divider,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'baseline',
                        }}
                      >
                        <Stack sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{item.name}</Typography>
                          <Typography> {isDisabled && <DoneIcon />}</Typography>
                        </Stack>
                        <Typography sx={{ color: '#9E9EAD', fontSize: '14px', mt: 1 }}>{item.description}</Typography>
                      </ListItemButton>
                    );
                  })}
                </List>
              ) : (
                <Typography sx={{ textAlign: 'center', fontSize: '14px', fontWeight: '500', p: 2 }}>
                  There is no existing modifier groups to select
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            onClick={handleCreateModifierGroup}
            sx={{ px: 3, py: 1, width: '100%', borderTop: 1, borderColor: (theme) => theme.palette.divider }}
          >
            <Private permission="MENU_EDIT">
              <Button>+ Add New</Button>
            </Private>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default ModifierOptions;
