import {
  SET_MENUS_V2,
  SET_MENU_CATEGORIES_V2,
  SET_LOADING,
  SET_CATEGORY_PRODUCTS_V2,
  SET_PAGINATION_V2,
  SET_MENU_PARAMS_V2,
  SET_MENU_CATEGORIES_V2_SOCKET_UPDATE,
  SET_MENU_CATEGORIES_V2_SOCKET_CREATE,
  SET_MENU_CATEGORIES_V2_SOCKET_DELETE,
  SET_MENU_CATEGORIES_V2_SOCKET_SORT,
  SET_MENU_PRODUCTS_ALL,
  SET_MENU_MODIFIERS_ALL,
  SET_LINK_PROVIDER_VISIBILITY,
  SET_LINK_PROVIDER,
  SET_TARGET_CONNECTED_PROVIDERS,
  SET_CONNECTED_PROVIDERS,
  RESET_LINK_PROVIDER,
  SET_LINK_PROVIDER_LOADING,
  SET_MENU_MODIFIERS_LOADING,
  SET_CURRENT_MODIFIER,
  SET_FORM_PRODUCT,
  SET_PROVIDER_DOORDASH_LOADING,
  SET_PROVIDER_GRUBHUB_LOADING,
} from "redux/actions/types";
export const initialMenu = {
  name: "",
  products: [],
  loading: false,
  active: 0,
  formValid: false,
};
const initialLinkProvider = {
  target: "",
  providers: [],
  id: "",
  categoryId: "",
  providerLinkVisibility: false,
  connectedProviders: {},
  targetConnectedProviders: {},
  loading: false,
};
const initialState = {
  loading: true,
  isEdit: false,
  linkProvider: initialLinkProvider,
  menu: { productsAll: [] },
  formValid: false,
  paginationConfig: {
    page: 1,
    limit: 50,
    skip: 0,
    count: null,
  },
  params: {
    activeMenuId: "",
    activeCategoryId: "",
    activeProductId: "",
  },
  formProduct: {},
  isDoordashProviderLoading: false,
  isGrubHubProviderLoading: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MENUS_V2:
      state.menu.categories &&
        state.menu.categories.map((item) => {
          const found = payload.categories.find(
            (upcomingCategory) => item._id === upcomingCategory._id
          );
          if (found) {
            item.availability = found.availability;
            return item;
          }
          return item;
        });
      return {
        ...state,
        menu: payload,
      };
    case SET_FORM_PRODUCT:
      return {
        ...state,
        formProduct: { ...payload },
      };
    case SET_CURRENT_MODIFIER:
      return {
        ...state,
        menu: {
          ...state.menu,
          currModifier: payload,
        },
      };
    case SET_MENU_CATEGORIES_V2:
      return {
        ...state,
        menu: {
          ...state.menu,
          categories: payload,
        },
      };
    case SET_MENU_PRODUCTS_ALL:
      return {
        ...state,
        menu: {
          ...state.menu,
          productsAll: payload,
        },
      };
    case SET_MENU_MODIFIERS_ALL:
      return {
        ...state,
        menu: {
          ...state.menu,
          modifiersAll: payload || [],
        },
      };
    case SET_MENU_MODIFIERS_LOADING:
      return {
        ...state,
        menu: {
          ...state.menu,
          loading: payload,
        },
      };
    case SET_CATEGORY_PRODUCTS_V2:
      return {
        ...state,
        menu: {
          ...state.menu,
          categories: payload,
        },
      };
    case SET_PAGINATION_V2:
      return {
        ...state,
        paginationConfig: {
          ...state.paginationConfig,
          ...payload,
        },
      };
    case SET_MENU_PARAMS_V2:
      return {
        ...state,
        params: {
          ...state.params,
          ...payload,
        },
      };
    case SET_MENU_CATEGORIES_V2_SOCKET_UPDATE:
      return {
        ...state,
        menu: {
          ...state.menu,
          categories: payload,
        },
      };
    case SET_MENU_CATEGORIES_V2_SOCKET_CREATE:
      return {
        ...state,
        menu: {
          ...state.menu,
          categories: [...state.menu.categories, payload],
        },
      };
    case SET_MENU_CATEGORIES_V2_SOCKET_DELETE:
      const filtered = state.menu.categories.filter(
        (item) => item.id !== payload
      );
      return {
        ...state,
        menu: {
          ...state.menu,
          categories: filtered,
        },
      };
    case SET_MENU_CATEGORIES_V2_SOCKET_SORT:
      return {
        ...state,
        ...payload,
      };
    case SET_LINK_PROVIDER_VISIBILITY:
      return {
        ...state,
        linkProvider: {
          ...state.linkProvider,
          providerLinkVisibility: payload,
        },
      };
    case SET_LINK_PROVIDER:
      return {
        ...state,
        linkProvider: {
          ...state.linkProvider,
          ...payload,
        },
      };
    case SET_LINK_PROVIDER_LOADING:
      return {
        ...state,
        linkProvider: {
          ...state.linkProvider,
          loading: payload,
        },
      };
    case RESET_LINK_PROVIDER:
      return {
        ...state,
        linkProvider: initialLinkProvider,
      };
    case SET_CONNECTED_PROVIDERS:
      return {
        ...state,
        linkProvider: {
          ...state.linkProvider,
          connectedProviders: {
            ...state.linkProvider.connectedProviders,
            ...payload,
          },
        },
      };
    case SET_TARGET_CONNECTED_PROVIDERS:
      return {
        ...state,
        linkProvider: {
          ...state.linkProvider,
          targetConnectedProviders: {
            ...state.linkProvider.targetConnectedProviders,
            ...payload,
          },
        },
      };
    case "MENU_ORDER_CATEGORIES":
      return { ...state, menu: payload };
    case "CATEGORY_IS_EDIT":
      return { ...state, isEdit: payload };
    case "FORM_VALIDITY":
      return { ...state, formValid: payload };
    case "RESET_MENUS":
      return { ...initialState };
    case SET_LOADING:
      return { ...state, loading: payload };
    case SET_PROVIDER_DOORDASH_LOADING:
      return { ...state, isDoordashProviderLoading: payload };
    case SET_PROVIDER_GRUBHUB_LOADING:
      return { ...state, isGrubHubProviderLoading: payload };
    default:
      return state;
  }
};
