import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import ModifierForm from 'components/MenuNew/FormView/ModifierFormGroup/index';
import Preloader from 'components/Preloader';
import useQuery from 'hooks/useQuery';
import FormFooter from './FormFooter';
import { addModifier, deleteModifier, editModifierV2, getModifiersAll } from 'redux/actions/Menu/modifiers';
import FormHeader from 'pages/MenuNew/FormsView/NewModifierGroup/FormHeader';
import { confirm } from 'components/MenuNew/ConfirmModal';
import { makeStyles } from '@mui/styles';
import { getModifierDataById } from 'redux/actions/Menu/modifiers';
import { set } from 'lodash';
import { MENU_TYPES, CONTEXT_TYPES } from 'constants/menu';
import Private from 'components/Private';
import { getMenusV2 } from 'redux/actions/Menu/menuV2';

const initialData = {
  min: 0,
  max: 0,
  free: 0,
  name: '',
  title: '',
  description: '',
  list: [
    {
      name: '',
      price: 0,
      priceOverride: [],
      optionSort: 0,
    },
  ],
  isActive: true,
  internalName: '',
  isOnePrice: true,
};

const useStyles = makeStyles((theme) => ({
  preloader: {
    position: 'fixed !important',
  },
}));

function compare(a, b) {
  if (a.sort < b.sort) {
    return -1;
  }
  if (a.sort > b.sort) {
    return 1;
  }
  return 0;
}

const FormData = ({
  setCurrentModifier = () => {},
  currentModifier,
  currentProduct,
  modifierMethods,
  setTabValue,
  setTitleModifier = () => {},
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isInOverview = history.location.pathname.includes('/overview');
  const isInModifierGroupPage = history.location.pathname.includes('/menu/modifierGroups');
  const [loading, setLoading] = useState(false);
  const { menu } = useSelector(({ menu }) => menu);

  const query = useQuery();
  const mode = query.get('mode');
  const productId = query.get('pid');
  const categoryId = query.get('cid');
  const modifierId = query.get('mid');
  const [formState, updateFormState] = useState(initialData);
  const [formErrors, updateFormErrors] = useState({
    name: '',
    description: '',
    list: [{ name: '', price: '', priceOverride: [{ name: '', price: '' }] }],
  });
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (mode === 'edit') {
      setLoading(true);
      dispatch(getModifierDataById(modifierId))
        .then((res) => {
          if (!res.title) {
            res.title = res.name;
          }
          if (!res.description) {
            res.description = '';
          }
          if (!res.free) {
            res.free = '';
          }
          if (!res.min) {
            res.min = '';
          }
          if (!res.max) {
            res.max = '';
          }
          updateFormState(res);
          setTitleModifier(res.name);
          setCurrentModifier(res);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      let connectedProviders;
      if (isInModifierGroupPage) {
        connectedProviders = menu.connectedProviders;
      } else {
        connectedProviders = currentProduct?.connectedProviders;
      }
      if (connectedProviders) {
        Object.keys(connectedProviders).map((item) => {
          if (currentProduct?.connectedProviders[item]) {
            initialData.list[0].priceOverride.push({
              context_type: 'MOD_ITEM',
              context_value: item,
              price: +currentProduct?.price || '',
            });
          }
        });
      }
      setTitleModifier('');
      setCurrentModifier(initialData);
      const initData = {
        min: 0,
        max: 0,
        free: 0,
        name: '',
        title: '',
        description: '',
        list: [
          {
            name: '',
            price: '',
            priceOverride: [],
            optionSort: 0,
          },
        ],
        isActive: true,
        internalName: '',
        isOnePrice: true,
      };
      updateFormState(initData);
    }
  }, [modifierId]);

  const handleErrors = (formState, formAddOption) => {
    let haveErrors = false;
    const formErrorsCopy = { ...formErrors };
    if (!formState.name && !formAddOption) {
      haveErrors = true;
      set(formErrorsCopy, 'name', { error: true, message: 'This field is required' });
    }
    formState.list.forEach((listItem, listItemIndex) => {
      if (!listItem.name) {
        haveErrors = true;
        set(formErrorsCopy, `list[${listItemIndex}].name`, { error: true, message: 'This field is required' });
      }
      if (listItem.price === '' && listItem.price !== 0) {
        haveErrors = true;
        set(formErrorsCopy, `list[${listItemIndex}].price`, { error: true, message: 'This field is required' });
      }
      if (formState?.products) {
        formState.products.map((item) => {
          if (currentProduct?._id === item._id) {
            if (currentProduct.connectedProviders[MENU_TYPES.ubereats] && listItem.price > 374) {
              haveErrors = true;
              set(formErrorsCopy, `list[${listItemIndex}].price`, {
                error: true,
                message: "Price can't exceed $375.00 for Ubereats",
              });
            }
          }
        });
      }

      listItem.priceOverride.forEach((priceOverrideItem, priceOverrideItemIndex) => {
        if (priceOverrideItem.context_type === CONTEXT_TYPES.modifierItem && priceOverrideItem.name === '') {
          haveErrors = true;
          set(formErrorsCopy, `list[${listItemIndex}].priceOverride[${priceOverrideItemIndex}].name`, {
            error: true,
            message: 'This field is required',
          });
        }
        if (priceOverrideItem.price === '' && priceOverrideItem.price !== 0) {
          haveErrors = true;
          set(formErrorsCopy, `list[${listItemIndex}].priceOverride[${priceOverrideItemIndex}].name`, {
            error: true,
            message: 'This field is required',
          });
        }
      });
    });
    updateFormErrors(formErrorsCopy);
    return haveErrors;
  };

  const handleValidSubmit = (e) => {
    e.preventDefault();
    // formErrors, updateFormErrors
    const priceError = formErrors?.list?.filter((item) => item?.price?.error) || [];
    if (handleErrors(formState) || priceError.length || formErrors?.['title']?.error) {
      return;
    }
    formState.list.forEach((item, index) => {
      item.optionSort = index;
    });
    setLoading(true);
    if (mode != 'edit') {
      dispatch(addModifier({ ...formState }))
        .then((res) => {
          if (!isInModifierGroupPage) {
            goToModifierOptions(res);
          } else {
            history.push({
              search: `entityType=modifier&mid=${res._id}&mode=edit`,
            });
            setIsDirty(false);
          }
        })
        .finally(() => {
          // reset(values);
          setLoading(false);
        });
    } else {
      dispatch(editModifierV2({ ...formState }))
        .then((res) => {
          goToModifierOptions(res);
          setIsDirty(false);
        })
        .finally(() => {
          // reset(values);
          setLoading(false);
        });
    }
  };

  const goToModifierOptions = (res = null) => {
    if (!isInModifierGroupPage) {
      const exist = modifierMethods.fields.findIndex((item) => item?._id === res?._id);
      if (exist !== -1) {
        modifierMethods.update(exist, res);
      } else {
        modifierMethods.append(res);
      }

      setTabValue(null, 1);
      history.push({
        search: `?entityType=item&pid=${productId}&cid=${categoryId}&mode=edit`,
      });
    } else {
      history.push({
        search: `entityType=modifier&mid=${res?._id || currentModifier?._id}&mode=edit`,
      });
    }
  };

  // const changeIsOnePrice = (checked) => {
  //   updateFormState({ ...formState, isOnePrice: !checked });
  // };

  const handleDelete = async () => {
    const confirmed = await confirm({
      title: 'Are you sure you want to delete the modifier group? It will be deleted from all the items it was add to',
      message: '',
      cancelText: 'Cancel',
      confirmText: 'Yes, delete',
      confirmColor: 'primary',
    });
    if (confirmed) {
      setLoading(true);
      dispatch(deleteModifier(modifierId)).finally(() => {
        history.push('/menu/modifierGroups');
        // reset(initialData);
        setLoading(false);
        dispatch(getMenusV2());
      });
      dispatch(getModifiersAll());
    }
  };

  return (
    <form style={{ height: 'inherit' }} onSubmit={handleValidSubmit}>
      {loading && <Preloader overlay={true} className={classes.preloader} />}
      {isInModifierGroupPage ? (
        <Box>
          <FormHeader title={formState.name} handleDelete={handleDelete} mode={mode} />
        </Box>
      ) : (
        <></>
      )}
      <Box sx={{ height: 'calc(100% - 174.5px)', overflowY: 'auto', px: 4, py: 3 }}>
        <Private permission="MENU_EDIT" disabled={true}>
          <ModifierForm
            isInOverview={isInOverview}
            isInModifierGroupPage={isInModifierGroupPage}
            mode={mode}
            // changeIsOnePrice={changeIsOnePrice}
            currentProduct={currentProduct}
            formState={formState}
            formErrors={formErrors}
            setIsDirty={setIsDirty}
            updateFormErrors={updateFormErrors}
            updateFormState={updateFormState}
            handleErrors={handleErrors}
          />
        </Private>
      </Box>
      <Box>
        <FormFooter
          isDisable={isDirty}
          isInOverview={isInOverview}
          goToModifierOptions={goToModifierOptions}
          mode={mode}
        />
      </Box>
    </form>
  );
};

export default memo(FormData);
