import React, { useEffect, useState } from "react";
import cs from "classnames";
import Preloader from "components/Preloader";
import { AvInput } from "availity-reactstrap-validation";
import { uploadAttachments, unlinkImage } from "redux/actions/file";
// import TextField from '@mui/material/TextField';
import CustomTextInput from "components/Forms/CustomTextInput";

import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material";

const ImageUpload = ({
  name,
  initialValue,
  removable,
  size,
  disabled,
  className = "",
  setFormValue = () => {},
  setImage = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleChange = ({ target: { files } }) => {
    const image = files[0];
    const reader = new FileReader();
    const formData = new FormData();
    formData.append("image", image);
    reader.onload = () => {
      setLoading(true);
      uploadAttachments(formData)
        .then(({ image }) => {
          if (image.url) {
            setValue(image);
            setImage(image);
            setFormValue("avatar", image);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (image) {
      reader.readAsDataURL(image);
    }
  };

  const handleDelete = () => {
    unlinkImage(value.imageId).then(() => {
      setImage("");
      setValue("");
      setFormValue("avatar", "");
    });
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div
      className={cs("image-upload", className, {
        disabled,
        empty: !value?.url,
      })}
      style={size ? { width: size + "px", height: size + "px" } : {}}
    >
      <TextField value={value?.url} hidden type="text" name={name} />
      <label>
        {!!value?.url && <img src={`${value?.url}?tr=w-150,h-150`} alt="" />}
        <input
          type="file"
          onChange={handleChange}
          disabled={disabled}
          accept="image/png, image/jpeg"
        />
        <span className={cs({ "image-loading": loading, empty: !value?.url })}>
          {loading ? <Preloader /> : <i className="icon icon-camera"></i>}
        </span>
      </label>
      {!disabled && removable && !!value?.url && (
        <IconButton
          sx={{
            background: "red",
            color: "white",
            padding: "5px",
            position: "absolute",
            borderRadius: "5px",
          }}
          color="error"
          variant="contained"
          size="sm"
          onClick={handleDelete}
          className="delete-image p-1"
        >
          <i className="icon icon-trash-2"></i>
        </IconButton>
      )}
    </div>
  );
};

export default ImageUpload;
