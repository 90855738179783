export const PAGE_TITLES = {
  home: "Dashboards",
  orders: "Orders",
  reports: "Reports",
  providers: "Providers",
  website: "Website",
  menu: "Menu",
  settings: "Settings",
  tutorials: "Tutorials",
  help: "Help",
  schedule: "Schedule",
};

export const SETTINGS_TITLES = {
  account: "Account",
  business: "Business",
  usersAndRoles: "User and Roles",
  billing: "Billing Info",
  printer: "Printers",
  events: "Event Notifications",
  brands: "Brand Locations",
  admin: "Admin",
};
