import moment from 'moment';
const now = new Date();

export const API_BASE_URL =
  process.env.NODE_ENV === 'development' ? 'https://stage-api01.orders.co/' : 'https://api01.orders.co/';
export const PHONE_REGEXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const GOOGLE_API_KEY = 'AIzaSyDYfjHl41cQl4BQZZ35YKPh-sHhL0Vv2ZM';

export const ORDER_STATUS = {
  unconfirmed: {
    name: 'Unconfirmed',
    value: 'unconfirmed',
    color: 'red',
    icon: 'icon-bell',
  },
  confirmed: { name: 'Confirmed', value: 'confirmed', color: 'green' },
  in_progress: { name: 'Processing', value: 'in_progress', color: 'orange' },
  ready_for_pickup: {
    name: 'Ready for pickup',
    value: 'ready_for_pickup',
    color: '#00B894',
  },
  picked_up: { name: 'Picked up', value: 'picked_up', color: '#6C5CE7' },
  delivered: { name: 'Delivered', value: 'delivered', color: '#6C5CE7' },
  rejected: { name: 'Rejected', value: 'rejected', color: 'red' },
  canceled: { name: 'Canceled', value: 'canceled', color: 'red' },
  scheduled: { name: 'Scheduled', value: 'scheduled', color: '#1373E6' },
  completed: { name: 'Completed', value: 'completed', color: '#6C5CE7' },
};
export const INITIAL_STATUS = 'all';
export const ORDERS_FILTER_STATUS = {
  all: { name: 'All', value: 'all' },
  unconfirmed: { name: 'Unconfirmed', value: 'unconfirmed' },
  in_house: { name: 'Processing', value: 'in_house' },
  ready_for_pickup: { name: 'Ready', value: 'ready_for_pickup' },
  out_the_door: { name: 'Picked up', value: 'out_the_door' },
  rejected: { name: 'Rejected', value: 'rejected' },
  canceled: { name: 'Canceled', value: 'canceled' },
  scheduled: { name: 'Scheduled', value: 'scheduled' },
};
export const INITIAL_PERIOD = 'last24h';

export const getPeriods = () => ({
  last24h: {
    label: 'Last 24 hours',
    start: Date.now() - 86400000,
    end: +new Date(moment(now).endOf('day')),
  },
  last7d: {
    label: 'Last 7 days',
    start: Date.now() - 86400000 * 7,
    end: +new Date(moment(now).endOf('day')),
  },
  lastM: {
    label: 'Last 30 days',
    start: Date.now() - 86400000 * 30,
    end: +new Date(moment(now).endOf('day')),
  },
});

export const getReportsFilterPeriods = () => ({
  day: {
    label: 'Last 24 hours',
    start: +new Date(moment(now).subtract('hours', 24)),
    end: +new Date(moment(now)),
  },
  week: {
    label: 'Last 7 days',
    start: +new Date(moment(now).subtract('days', 7)),
    end: +new Date(moment(now).endOf('day')),
  },
  month: {
    label: 'Last 30 days',
    start: +new Date(moment(now).subtract('days', 30)),
    end: +new Date(moment(now).endOf('day')),
  },
});
