import instance, { gatewayInstance } from 'services/api';
import { ORDERS_FILTER_STATUS } from 'constants/config';
import { SET_ORDERS, SET_ORDERS_PAGINATION, UPDATE_MODIFIED_ORDERS } from 'redux/actions/types';
import { toast } from 'react-toastify';

// Get Unconfirmed Order.
export const getUnconfirmedOrders = () => (dispatch) => {
  const status = ORDERS_FILTER_STATUS.unconfirmed.value;
  dispatch({ type: 'ORDERS_REQ', status });
  return instance.get(`/orders/list/${status}`).then(({ data: { data } }) => {
    const notifyLength = data.reduce((acc, { notify }) => (notify ? acc + 1 : acc), 0);
    dispatch({ type: 'ORDERS_RES', payload: data, status });
    return notifyLength;
  });
};

// Get Orders.
export const getOrders = () => (dispatch, getState) => {
  const {
    orders: { paginationConfig, params },
  } = getState();
  const { skip = 0, limit = 100 } = paginationConfig;
  const { status, start, end } = params;
  return instance
    .get(`/orders/v2/list/${status}`, { params: { start, end, skip } })
    .then(({ data: { data } }) => {
      const { orders, count } = data;
      const pageCount = Math.ceil(count / limit);
      dispatch({ type: SET_ORDERS, payload: orders });
      dispatch({ type: SET_ORDERS_PAGINATION, payload: { count: pageCount } });
      return count;
    });
};

export const orderChangedInProvider = (data) => (dispatch) => {
  dispatch({ type: UPDATE_MODIFIED_ORDERS, payload: data });
};

// Update Orders Status.
export const updateOrderStatus = (data) => (dispatch, getState) => {
  const {
    orders: { orderList },
  } = getState();
  const ordersListCopy = [...orderList];
  const newDataIndex = orderList.findIndex((item) => item.id === data.id);
  if (newDataIndex != -1) {
    ordersListCopy[newDataIndex] = data;
    dispatch({ type: SET_ORDERS, payload: ordersListCopy });
  }
};

// Get Reports.
export const getReports = (uuid, start, end, period, businessId) => {
  if (businessId) {
    instance.defaults.headers.business = businessId;
  }
  return instance.get(`/orders/reports/${uuid}`, { params: { start, end, period } });
};

// get Order.
export const getOrder = (id) => {
  return instance.get(`/orders/${id}`);
};

//reject order
export const rejectOrder = (id, provider, owner, body) => {
  return instance
    .put(`/orders/${provider}/order/cancel`, { id, ...body })
    .then((res) => res)
    .catch((err) => {
      console.log('====================================');
      console.log(err);
      console.log('====================================');
      return Promise.reject(err);
    });
};

// Put Change Order Status.
export const changeOrderStatus = (id, body) => {
  return instance
    .put(`/orders/${id}`, body)
    .then((data) => data)
    .catch((err) => {
      if (err) {
        toast.error(err?.response?.message);
      }
    });
};

//  Put Confirm Order.
export const confirmOrder = (id, provider, owner, body) => {
  return gatewayInstance.post(`/${provider}/order/confirm/`, { id, ...body }).then((res) => res);
  // .catch((err) => toast.error(err.message));
};

// Get Print Order.
export const printOrder = (id, type) =>
  instance.get(`/orders/${id}/print`, { params: { type } }).then(({ data: { data } }) => data);
