import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { get } from 'lodash';

const CustomTextInput = ({
  name,
  id,
  sx,
  children,
  label = '',
  validate,
  inputProps,
  hidden,
  onChange,
  disabled,
  multiline,
  rows,
  select,
  InputProps,
  itemIndex,
  ...params
}) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  let parsedError = get(errors, name);

  return (
    <Controller
      {...params}
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <TextField
            size="small"
            hidden={hidden}
            error={!!parsedError || params.error || false}
            helperText={parsedError ? parsedError.message : params.helperText || ''}
            label={label}
            inputRef={field.ref}
            disabled={disabled}
            InputProps={InputProps}
            sx={sx}
            multiline={multiline}
            rows={rows}
            select={select}
            inputProps={inputProps}
            {...field}
          >
            {children}
          </TextField>
        );
      }}
    />
  );
};

export default CustomTextInput;
