import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { getMenusV2 } from "redux/actions/Menu/menuV2";
import Overview from "./Overview";
import MenuLinkDrawer from "./MenuLinkDrawer";
import CategoriesList from "./Categories/CategoriesList";
import Products from "./Products/Products";
import Modifiers from "./Modifiers/Modifiers";
import { getModifiersAll } from "redux/actions/Menu/modifiers";
import useQuery from "hooks/useQuery";
import MasterMenuHeader from "./MasterMenuHeader";

const MenuNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const { menu } = useSelector(({ menu }) => menu);
  const { modifiersAll } = menu;
  const query = useQuery();
  const catId = query.get("cid");
  const mode = query.get("mode");

  useEffect(() => {
    dispatch(getMenusV2());
    if (!modifiersAll) {
      dispatch(getModifiersAll());
    }
    if (mode != "edit") {
      history.push(pathname);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <MasterMenuHeader />
      </Grid>
      <Grid item xs={12}>
        <Switch>
          <Route exact path="/menu/overview" component={Overview} />
          <Route exact path="/menu/categories" component={CategoriesList} />
          {/* <Route exact path="/menu/items" component={Products} /> */}
          <Route path="/menu/modifierGroups" component={Modifiers} />
          <Redirect to="/menu/overview" />
        </Switch>
      </Grid>
      <MenuLinkDrawer />
    </Grid>
  );
};

export default MenuNew;
