import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleSocketsEventsByType } from "redux/sockets";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Menu from 'pages/Menu';
import Menu from "pages/MenuNew/index";
import { getUserData } from "redux/actions/user";
import Preloader from "components/Preloader";
import ProtectedRoute from "components/ProtectedRoute";
import { useSocket } from "providers/Socket";
import { getProviders } from "redux/actions/providers";
import { PROVIDER_CONNECTED } from "constants/providerStatuses";
import { setInstance } from "services/api";
import useQuery from "hooks/useQuery";

import {
  RESET_MODIFIED_ORDERS,
  ORDERS_EMPTY,
  RESET_CANCELED_ORDERS,
} from "redux/actions/types";

function Main() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { subscribe, unsubscribe, eventEmit } = useSocket();
  const [overlay, setOverlay] = useState(false);
  const [joined, setJoined] = useState(false);
  const [canGetData, handleCanGetData] = useState(false);

  const {
    data,
    loading,
    newOrders,
    modifiedOrders,
    lastUpdatedId,
    lastCanceledId,
    canceledOrders,
  } = useSelector(
    ({
      user: { data, loading },
      orders: {
        newOrders,
        modifiedOrders,
        lastUpdatedId,
        lastCanceledId,
        canceledOrders,
      },
      menu: { menus },
    }) => ({
      data,
      loading,
      newOrders,
      modifiedOrders,
      canceledOrders,
      lastUpdatedId,
      lastCanceledId,
    })
  );
  const { currentBusiness } = useSelector(({ business }) => business);

  const [allDisconnected, setAllDisconnected] = useState(true);
  const { items, stopResumeOrders } = useSelector(({ providers }) => providers);

  let query = useQuery();
  const token = query.get("token");
  const business = query.get("business");

  useEffect(() => {
    if (token && business) {
      setInstance(token, business);
      localStorage.setItem("accessToken", token);
      localStorage.setItem(
        "businessInfo",
        JSON.stringify({ label: business.label || "", id: business })
      );
      handleCanGetData(!canGetData);
    }
    dispatch(getUserData());
    dispatch(getProviders());
  }, [token, business]);

  // useEffect(() => {
  //   document.querySelector('[name="viewport"]').content =
  //     "width=1440,initial-scale=1,shrink-to-fit=yes";
  //   dispatch(getUserData());
  //   dispatch(getProviders());
  // }, [dispatch, canGetData]);

  useEffect(() => {
    const handleSocketEvent = (data) => {
      handleSocketsEventsByType(data);
    };
    subscribe("event-bus", handleSocketEvent);

    return () => {
      unsubscribe("event-bus", handleSocketEvent);
    };
  }, [dispatch, subscribe, unsubscribe, eventEmit, data]);

  useEffect(() => {
    if (data) {
      setJoined(true);
      if (!joined) {
        eventEmit("join", { id: data._id });
      }
    }
  }, [data, joined]);

  useEffect(() => {
    if (newOrders.length || modifiedOrders.length || canceledOrders.length) {
      setOverlay(true);
    } else {
      setOverlay(false);
    }
  }, [newOrders.length, modifiedOrders.length, canceledOrders.length]);

  useEffect(() => {
    const filteredList = items.filter((item) => item.srv !== "postmates");
    filteredList.forEach((item, index) => {
      if (
        item.status === PROVIDER_CONNECTED ||
        currentBusiness?.settings?.websiteInfo?.pause
      ) {
        setAllDisconnected(false);
      }
    });
  }, [items, currentBusiness]);

  return (
    <Box sx={{ display: "flex", m: 3 }}>
      {!data && loading ? (
        <Preloader overlay className="app-loader" />
      ) : (
        <Switch>
          <Route path="/menu" component={Menu} />

          <Redirect to="/menu/overview" />
        </Switch>
      )}
    </Box>
  );
}

export default Main;
