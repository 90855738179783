import * as React from 'react';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '& .MuiDataGrid-row	': {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  '& .MuiDataGrid-sortIcon': {
    color: theme.palette.common.white,
  },
  '& .MuiDataGrid-filterIcon': {
    color: theme.palette.common.white,
  },
  '& .MuiDataGrid-menuIcon	': {
    '& .MuiButtonBase-root': { color: theme.palette.common.white },
  },
  '& .MuiTablePagination-toolbar': {
    '& p': { marginBottom: 0 },
  },

  '&.MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}));

export default StyledDataGrid;
