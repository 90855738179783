import React, { memo } from 'react';
import CustomTextInput from 'components/Forms/CustomTextInput';
import MultiSelectChips from 'components/Forms/MultiSelectChips';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { renderPermanently } from 'utils/soldOut';

const CategoryForm = ({ handleClickOpen, availability, data, isInOverview, mode }) => {
  const isEdit = mode === 'edit';
  return (
    <Box>
      <Grid container xl={isInOverview ? 12 : 6}>
        <Grid item xs={12} md={12} xl={isInOverview ? 12 : 6}>
          <CustomTextInput sx={{ width: '100%' }} name="name" label={'Name'} />
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <CustomTextInput
            rows={4}
            multiline={true}
            sx={{ width: '100%', mt: 2 }}
            name="description"
            label={'Description'}
          />
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <MultiSelectChips
            sx={{ width: '100%', mt: 2 }}
            name="availability"
            label="Schedule"
            options={availability}
            multiple={true}
            defaultValues={data.availability}
          />
        </Grid>
        <Grid item>
          <CustomTextInput hidden name="sort" />
        </Grid>
        <Grid item>
          {/* <Button
            onClick={handleClickOpen}
            sx={{
              mt: 2,
              padding: '8.4px',
              width: '100%',
              zIndex: 999,
            }}
            size="small"
            name="isActive"
            variant="contained"
          >
            Update availability
          </Button> */}
          <Box>
            <Typography
              sx={{ fontSize: '14px', color: '#EF3061', fontWeight: 500, justifyContent: 'center', display: 'flex' }}
              variant="body1"
            >
              {/* {renderPermanently(data, currentBusiness, moment)} */}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(CategoryForm);
