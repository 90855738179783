import { USER_LOADING, USER_LOADED, USER_LOGOUT } from 'constants/user';
const initialUser = () => {
  const token = window.localStorage.getItem('accessToken');
  return { authorized: !!token };
};

const initialState = {
  data: null,
  loading: true,
  authorized: false,
  ...initialUser(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADING:
      return { ...state, loading: action.payload };
    case USER_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGOUT:
      return { ...state, authorized: false };
    // return {
    //   state: {
    //     ...initialState,
    //     loading: false,
    //   },
    // };
    default:
      return state;
  }
};
