import startStopInitValues from 'constants/resumePause';

const init = {
  loading: false,
  showModal: false,
  submitted: false,
  provider: 'all',
  pause: true,
  data: {
    duration: startStopInitValues.duration[0].value,
    reason: startStopInitValues.reason[0].value,
    action: startStopInitValues.action[0].value,
  },
};

const initialState = {
  items: [],
  providerId: null,
  providerConnectData: {
    connectionStarted: false,
  },
  loading: false,
  stopResumeOrders: {
    statuses: {},
    submitted: false,
    loading: false,
    showModal: false,
    provider: 'all',
    pause: true,
    data: {
      duration: startStopInitValues.duration[0].value,
      reason: startStopInitValues.reason[0].value,
      action: startStopInitValues.action[0].value,
    },
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'PROVIDERS_REQ':
      return {
        ...state,
        loading: true,
      };
    case 'PROVIDER_CONNECT_DATA_RESPONSE':
      return {
        ...state,
        providerConnectData: {
          ...state.providerConnectData,
          ...payload,
        },
      };
    case 'EMPTY_PROVIDER_CONNECT_DATA_RESPONSE':
      return {
        ...state,
        providerConnectData: {
          connectionStarted: false,
        },
      };
    case 'PROVIDER_CONNECTION_STARTED':
      return {
        ...state,
        providerConnectData: {
          ...state.providerConnectData,
          connectionStarted: payload,
        },
      };
    case 'PROVIDERS_ERR':
      return {
        ...state,
        loading: false,
      };
    case 'PROVIDERS_RES':
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case 'PROVIDERS_START_RESUME':
      return {
        ...state,
        stopResumeOrders: {
          ...state.stopResumeOrders,
          ...payload,
        },
        loading: false,
      };
    case 'UPDATE_PROVIDER_RESUME_PAUSE_STATUSES':
      return {
        ...state,
        stopResumeOrders: {
          ...state.stopResumeOrders,
          statuses: {
            ...state.stopResumeOrders.statuses,
            ...payload,
          },
        },
        loading: false,
      };
    case 'PROVIDERS_START_RESUME_RESET':
      return {
        ...state,
        stopResumeOrders: {
          ...state.stopResumeOrders,
          ...init,
        },
        loading: false,
      };
    default:
      return state;
  }
};
