import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Private from 'components/Private';
import DragHandle from 'components/DragHandle';
import Preloader from 'components/Preloader';

const SortableModiferItem = SortableElement(({ value, handleEditModifier, onDelete, loadingModifierOption }) => (
  <Box
    key={value.id}
    variant="contained"
    sx={{
      background: '#FFD66E',
      border: 'none',
      color: 'black',
      mr: 1,
      mt: 2,
      padding: '8px 12px',
      borderRadius: '3px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    }}
  >
    <Box>
      <DragHandle className="pr-2  d-inline-block modifer-drag" />
      {value.name}
    </Box>
    <Box>
      <EditIcon
        sx={{ fontSize: '18px', mx: 1, cursor: 'pointer', '&:hover': { color: 'rgb(0 0 0 / 54%)' } }}
        onClick={() => handleEditModifier(value)}
      />
      <Private permission="MENU_EDIT">
        <CloseIcon
          sx={{ fontSize: '18px', mx: 1, cursor: 'pointer', '&:hover': { color: 'rgb(0 0 0 / 54%)' } }}
          onClick={() => onDelete(value)}
        />
      </Private>
    </Box>

    {loadingModifierOption && <Preloader overlay={true} />}
  </Box>
));

export default SortableModiferItem;
