import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const CustomCheckbox = ({ name, id, sx, label, inputProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControlLabel
            sx={sx}
            control={<Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />}
            label={label}
          />
        );
      }}
    />
  );
};

export default CustomCheckbox;
