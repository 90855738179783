import React, { memo, lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { arrayMove } from "react-sortable-hoc";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Typography,
  Divider,
  Stack,
  Button,
  TextField,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { set } from "lodash";
import { MENU_TYPES, CONTEXT_TYPES } from "constants/menu";
import Preloader from "components/Preloader";
import { priceTransform } from "helpers/formPrice";

// import SortableContainerList from './SortableContainerList';
const SortableContainerList = lazy(() => import("./SortableContainerList"));

const ModifierGroupForm = ({
  mode,
  // changeIsOnePrice,
  currentProduct,
  isInOverview,
  isInModifierGroupPage,
  formState,
  formErrors,
  updateFormState,
  updateFormErrors,
  handleErrors,
  setIsDirty,
}) => {
  const {
    isOnePrice,
    products,
    list,
    name,
    title,
    description,
    min,
    max,
    free,
  } = formState;
  const currentProductIsUber =
    currentProduct?.connectedProviders?.[MENU_TYPES.ubereats] || false;
  const { menu, loading } = useSelector(({ menu }) => menu);
  const sizeTypeModifiers = currentProduct?.sizeTypeModifiers;
  let connectedProviders = menu.connectedProviders;
  if (!isInModifierGroupPage) {
    connectedProviders = currentProduct?.connectedProviders;
  }
  const checkUber =
    products?.some((item) => item.connectedProviders?.[MENU_TYPES.ubereats]) ||
    currentProductIsUber;

  useEffect(() => {
    if (checkUber && list?.length) {
      const indexOf = list.findIndex((item) => item.price > 374);
      if (indexOf !== -1) {
        updateFormErrors({
          ...set(formErrors, `list[${indexOf}].price`, {
            error: true,
            message: "Price can't exceed $375.00 for Ubereats",
          }),
        });
      }
    }
  }, [checkUber, list, formState]);

  useEffect(() => {
    const newPriceOverride = [];
    const formStateCopy = { ...formState, list: [...formState.list] };
    formStateCopy.list.forEach((listItem, index) => {
      const priceOverride = [...listItem.priceOverride];
      sizeTypeModifiers &&
        sizeTypeModifiers[0] &&
        sizeTypeModifiers[0].list.forEach((currentItem, itemIndex) => {
          const found = listItem.priceOverride.find((item) => {
            return item.context_value === currentItem._id;
          });
          if (!found) {
            priceOverride.push({
              context_type: "MOD_ITEM",
              context_value: currentItem?._id,
              name: currentItem.name,
              price: 0,
              priceOverride: newPriceOverride,
            });
          }
        });
      listItem.priceOverride = priceOverride;
    });
    updateFormState(formStateCopy);
  }, [isOnePrice, currentProduct?.isOnePrice]);

  const handleAddItem = () => {
    if (handleErrors(formState, true)) {
      return;
    }
    if (isOnePrice) {
      const withNewList = {
        ...formState,
        list: [
          {
            name: "",
            price: 0,
            priceOverride: [
              {
                context_type: CONTEXT_TYPES.provider,
                context_value: MENU_TYPES.main,
                price: 0,
              },
            ],
            optionSort: 0,
            connectedProviders: connectedProviders,
          },
          ...list,
        ],
      };
      updateFormState(withNewList);
    } else {
      const newPriceOverride = [];
      const priceOverride = [];
      if (connectedProviders) {
        Object.keys(connectedProviders).map((modItem) => {
          if (connectedProviders[modItem]) {
            newPriceOverride.push({
              context_type: CONTEXT_TYPES.provider,
              context_value: modItem,
              name: modItem,
              price: 0,
            });
          }
        });
      }
      sizeTypeModifiers &&
        sizeTypeModifiers[0] &&
        sizeTypeModifiers[0].list.forEach((currentItem) => {
          priceOverride.push({
            context_type: "MOD_ITEM",
            context_value: currentItem?._id,
            name: currentItem.name,
            price: 0,
            priceOverride: newPriceOverride,
          });
        });
      const withNewList = {
        ...formState,
        list: [
          {
            name: "",
            price: 0,
            priceOverride,
            optionSort: 0,
            connectedProviders: connectedProviders,
          },
          ...list,
        ],
      };
      updateFormState(withNewList);
    }
    setIsDirty(true);
  };

  const handleDeleteItem = (index) => {
    const withNewList = {
      ...formState,
      list: formState.list.filter((item, listIndex) => listIndex !== index),
    };
    const newFormErrors = {
      ...formErrors,
      list: formErrors.list.filter((item, listIndex) => listIndex !== index),
    };

    updateFormErrors(newFormErrors);
    setIsDirty(true);
    updateFormState(withNewList);
  };

  const handleSortEnd = (data) => {
    const { oldIndex, newIndex } = data;

    if (oldIndex !== newIndex) {
      const sortedList = arrayMove(formState.list, oldIndex, newIndex);
      updateFormState({ ...formState, list: sortedList });
      setIsDirty(true);
    }
  };

  const handleChangeIsOnePrice = (e) => {
    const checked = e.target.checked;
    const formStateCopy = { ...formState, list: [...formState.list] };
    const newPriceOverride = [];

    formStateCopy.isOnePrice = !checked;
    // changeIsOnePrice(e.currentTarget.checked);
    if (connectedProviders) {
      Object.keys(connectedProviders).map((modItem) => {
        if (connectedProviders[modItem]) {
          newPriceOverride.push({
            context_type: CONTEXT_TYPES.provider,
            context_value: modItem,
            name: modItem,
            price: 0,
          });
        }
      });
    }
    if (!checked) {
      formStateCopy.list.forEach((listItem, index) => {
        formStateCopy.list[index].priceOverride = newPriceOverride;
      });
    } else {
      formStateCopy.list.forEach((listItem, index) => {
        const priceOverride = [...listItem.priceOverride];
        sizeTypeModifiers &&
          sizeTypeModifiers[0] &&
          sizeTypeModifiers[0].list.forEach((currentItem, itemIndex) => {
            const found = listItem.priceOverride.find((item) => {
              return item.context_value === currentItem._id;
            });
            if (!found) {
              priceOverride.push({
                context_type: "MOD_ITEM",
                context_value: currentItem?._id,
                name: currentItem.name,
                price: 0,
                priceOverride: newPriceOverride,
              });
            }
          });
        listItem.priceOverride = priceOverride;
      });
    }
    updateFormState(formStateCopy);
  };

  const handleChangeField = ({ currentTarget }) => {
    let { value, name } = currentTarget;
    if (name.includes("price")) {
      value = priceTransform(value);
    }
    let copyFormErrors = { ...formErrors };
    if (!value) {
      copyFormErrors = set(formErrors, name, {
        error: true,
        message: "This field is required",
      });
      updateFormErrors({ ...copyFormErrors });
    } else {
      copyFormErrors = set(formErrors, name, { error: false, message: "" });
      if (formState?.products && formState.list) {
        formState.list.forEach((listItem) => {
          formState.products.map((item) => {
            if (currentProduct?._id === item._id) {
              if (
                currentProduct.connectedProviders[MENU_TYPES.ubereats] &&
                priceTransform(value) > 374
              ) {
                copyFormErrors = set(formErrors, name, {
                  error: true,
                  message: "Price can't exceed $375.00 for Ubereats",
                });
              }
            }
          });
        });
      }
    }
    const newData = set(formState, name, value);
    setIsDirty(true);
    updateFormState({ ...newData });
  };
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <Box>
          {mode === "edit" && isInOverview && products?.length ? (
            <Stack
              direction="row"
              alignItems="center"
              sx={{ py: 1, px: 2, background: "#DCE8FF", mb: 3, mt: 2 }}
            >
              <InfoOutlinedIcon />

              <Typography sx={{ ml: 2 }}>
                This Group is attached to other items as well. The changes will
                be applied everywhere.
              </Typography>
            </Stack>
          ) : null}
          <Grid container xl={isInOverview ? 12 : 6} flexDirection="column">
            <Grid item xl={isInOverview ? 8 : 5}>
              <TextField
                onChange={handleChangeField}
                value={name}
                size="small"
                sx={{ width: "100%", mt: 2 }}
                label="Name"
                name="name"
                error={formErrors.name.error}
                helperText={formErrors.name.message}
              />
            </Grid>
            <Grid item xl={isInOverview ? 8 : 5}>
              <TextField
                onChange={handleChangeField}
                value={title}
                size="small"
                sx={{ width: "100%", mt: 2 }}
                label="Internal Name"
                name="title"
                error={formErrors?.title?.error}
                helperText={formErrors?.title?.message}
              />
            </Grid>
            <Grid item xl={isInOverview ? 8 : 12}>
              <TextField
                onChange={handleChangeField}
                size="small"
                sx={{ width: "100%", mt: 2 }}
                multiline={true}
                name="description"
                label="Description"
                type="textarea"
                rows="3"
                value={description}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ mt: 3 }}>
            <Grid item xs={"auto"}>
              <Typography sx={{ color: "#3D4350", fontWeight: 500 }}>
                Modifier Options and Price
              </Typography>
            </Grid>
            <Grid item xs sx={{ ml: 3 }}>
              <Divider />
            </Grid>
          </Grid>
          <Stack direction="row" sx={{ my: 3 }}>
            {!isInModifierGroupPage && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={currentProduct?.isOnePrice}
                      onChange={handleChangeIsOnePrice}
                      checked={currentProduct?.isOnePrice ? false : !isOnePrice}
                      name="isOnePrice"
                    />
                  }
                  label="Modifier prices vary by size"
                />
              </FormGroup>
            )}
          </Stack>
          <Stack sx={{ width: "max-content", mb: 2 }}>
            <Button
              startIcon={<AddIcon />}
              sx={{ background: "#F2F2F2", color: "#333333" }}
              onClick={handleAddItem}
            >
              Add Option
            </Button>
          </Stack>
          <Grid item xl={isInOverview ? 12 : 6}>
            <Suspense fallback={<Preloader />}>
              <SortableContainerList
                handleSortEnd={handleSortEnd}
                fields={list}
                mode={mode}
                isOnePrice={isOnePrice}
                connectedProviders={connectedProviders}
                total={list?.length}
                sizeTypeModifiers={sizeTypeModifiers}
                onDelete={handleDeleteItem}
                isInOverview={isInOverview}
                formState={formState}
                handleChangeField={handleChangeField}
                formErrors={formErrors}
                updateFormState={updateFormState}
              />
            </Suspense>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Box sx={{ mt: 3 }}>
              <Grid container alignItems="center">
                <Grid item xs={"auto"}>
                  <Typography sx={{ color: "#3D4350", fontWeight: 500 }}>
                    Customers can
                  </Typography>
                </Grid>
                <Grid item xs sx={{ ml: 3 }}>
                  <Divider />
                </Grid>
              </Grid>
              <>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <TextField
                    sx={{ maxWidth: "200px" }}
                    inputProps={{ type: "number" }}
                    label="Select at least"
                    min="0"
                    name={`min`}
                    size="small"
                    value={min}
                    onChange={handleChangeField}
                  />
                  <TextField
                    inputProps={{ type: "number" }}
                    sx={{ ml: 1, maxWidth: "200px" }}
                    label="Select most"
                    min="0"
                    name={`max`}
                    size="small"
                    value={max}
                    onChange={handleChangeField}
                  />
                  <TextField
                    inputProps={{ type: "number" }}
                    sx={{ ml: 1, maxWidth: "200px" }}
                    label="Free options"
                    min="0"
                    name={`free`}
                    size="small"
                    value={free}
                    onChange={handleChangeField}
                  />
                  <Tooltip
                    title="Free options regardless add on price"
                    followCursor
                  >
                    <ErrorOutlineIcon sx={{ ml: 2 }} />
                  </Tooltip>
                </Stack>
              </>
              <Box sx={{ mt: 3 }}>
                <Grid container alignItems="center">
                  <Grid item xs={"auto"}>
                    <Typography
                      sx={{
                        color: "#3D4350",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      Connected Items List
                    </Typography>
                  </Grid>
                  <Grid item xs sx={{ ml: 3 }}>
                    <Divider />
                  </Grid>
                </Grid>
                <Stack direction="row" sx={{ mt: 2, flexWrap: "wrap" }}>
                  {products?.map((product) => (
                    <Box
                      key={product._id}
                      variant="contained"
                      sx={{
                        background: "#CFDFFF",
                        border: "none",
                        color: "black",
                        mr: 1,
                        mt: 2,
                        padding: "8px 12px",
                        borderRadius: "3px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {product.name}
                    </Box>
                  ))}
                </Stack>
              </Box>
              {isInOverview && (
                <Typography sx={{ fontSize: "13px", mt: 3 }}>
                  Modifier templates are going to be available under “Modifier
                  Groups” tab and can be chosen any time you are adding /
                  editing items. You can reuse the template as much as you want.
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

// const SortableList = SortableContainer(({ children }) => <div>{children}</div>);

export default memo(ModifierGroupForm);
