import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { PROVIDER_CONNECTED } from "constants/providerStatuses";
import { smallIcons } from "services/serviceIcons";

import {
  SET_LINK_PROVIDER_VISIBILITY,
  SET_LINK_PROVIDER,
  SET_CONNECTED_PROVIDERS,
  RESET_LINK_PROVIDER,
  SET_CHECKED_PROVIDER,
} from "redux/actions/types";
import { PROVIDERS } from "constants/menu";
import Preloader from "components/Preloader";
import { linkProviderTarget } from "redux/actions/Menu/menuV2";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    p: 2,
    fontSize: "12px",
    border: "1px solid #dadde9",
  },
}));

const MenuLinkDrawer = () => {
  const dispatch = useDispatch();
  const { menu, linkProvider } = useSelector(({ menu }) => menu);
  const billingPlan = useSelector(({ business }) => business);
  const { user } = useSelector(({ user }) => ({ user: user?.data }));

  const showTheDisabledProv =
    billingPlan?.currentBusiness?.billingInfo?.plan?.name !== "Web Bundle";
  const { items } = useSelector(({ providers }) => providers);
  const [confirmedMode, handleConfirmedMode] = useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [sendDataProvider, setSendDataProvider] = useState([]);
  const [isDisable, setIsDisable] = useState(false);

  const {
    connectedProviders,
    providerLinkVisibility,
    loading,
    target,
    targetConnectedProviders,
    categoryId,
  } = linkProvider;

  const anchor = "right";
  const showTheWebsite =
    billingPlan?.currentBusiness?.billingInfo?.plan?.name !== "App Bundle" &&
    targetConnectedProviders["website"];

  const toggleDrawer = (open) => () => {
    dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: open });
  };

  const handleChange = ({ target }, provider) => {
    const { checked } = target;
    let updatedProviders = [...isSelected];
    if (checked) {
      updatedProviders = [...isSelected, { [provider]: checked }];
    } else {
      updatedProviders = [...isSelected, { [provider]: checked }];
    }

    setIsSelected(updatedProviders);
    dispatch({
      type: SET_CONNECTED_PROVIDERS,
      payload: { [provider]: checked },
    });
  };

  const handleLinkProvider = () => {
    if (!confirmedMode) {
      const oldData =
        JSON.parse(localStorage.getItem(SET_CHECKED_PROVIDER)) || [];
      const sendData = [];
      oldData.map((item) => {
        Object.keys(connectedProviders).map((newItems) => {
          if (
            Object.keys(item)[0] === newItems &&
            item[Object.keys(item)[0]] !== connectedProviders[newItems] &&
            connectedProviders[newItems]
          ) {
            sendData.push({ provider: Object.keys(item)[0] });
          }
        });
      });
      setSendDataProvider(sendData);
      return handleConfirmedMode(true);
    } else {
      setIsDisable(true);
      dispatch(linkProviderTarget(sendDataProvider))
        .then(() => {
          setIsDisable(true);
          dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: false });
        })
        .catch(() => {
          setIsDisable(false);
        });
      setIsSelected([]);
    }
  };

  useEffect(() => {
    if (!providerLinkVisibility) {
      dispatch({ type: RESET_LINK_PROVIDER });
      handleConfirmedMode(false);
    }
    setIsDisable(false);
  }, [providerLinkVisibility]);

  useEffect(() => {
    return dispatch({ type: RESET_LINK_PROVIDER });
  }, []);

  const handleCancel = () => {
    if (confirmedMode) {
      handleConfirmedMode(false);
    } else {
      toggleDrawer(false)();
    }
  };

  const generateMenuTarget = () => {
    const res = {};
    items.forEach((providerItem) => {
      res[providerItem.srv] = providerItem.status == PROVIDER_CONNECTED;
    });
    return res;
  };

  const returnTarget = (target) => {
    const result = {
      targetConnected: {},
      targetData: "",
    };
    if (target === "item") {
      const category = menu.categories.find((item) => item._id === categoryId);
      result.targetData = "category";
      result.targetConnected = category?.connectedProviders;
    } else {
      result.targetData = "menu";
      result.targetConnected = generateMenuTarget();
    }
    return result;
  };

  const goToTarget = () => {
    dispatch({
      type: SET_LINK_PROVIDER,
      payload: {
        connectedProviders: targetConnectedProviders,
        target: returnTarget(target).targetData,
        targetConnectedProviders: returnTarget(target).targetConnected,
      },
    });
  };

  const arrayConnectedProviders = Object.keys(PROVIDERS).filter(
    (item) => targetConnectedProviders[item]
  );
  if (!arrayConnectedProviders.includes("website") && showTheWebsite) {
    arrayConnectedProviders.push("website");
  }
  const arrayDisconnectedProviders = Object.keys(PROVIDERS).filter(
    (item) => !targetConnectedProviders[item] && item != "website"
  );

  return (
    <Drawer
      anchor={anchor}
      open={providerLinkVisibility}
      onClose={toggleDrawer(false)}
    >
      <Box sx={{ width: 340, p: 4, position: "relative", height: "100%" }}>
        {loading && <Preloader overlay={true} />}
        <Box>
          <Typography
            sx={{ fontWeight: "bold", lineHeight: "31.92px" }}
            variant="h5"
          >
            Select Providers to sync the menu items
            <Typography
              component={"span"}
              style={{
                fontSize: "12px",
                padding: "8px",
                background: "#E2E2E2",
                borderRadius: "100px",
                marginLeft: "8px",
                textTransform: "capitalize",
              }}
            >
              {target.charAt(0).toUpperCase() + target.slice(1)}
            </Typography>
          </Typography>
          <Typography
            sx={{
              fontWeight: "400",
              lineHeight: "18px",
              fontSize: "12px",
              color: "#777777",
              mt: 1,
            }}
          >
            {!confirmedMode
              ? "You can share your menu with connected providers only."
              : "You requested to sync the menus with the providers menus mentioned below. "}
          </Typography>
          {confirmedMode && (
            <Typography sx={{ color: "#141414", fontSize: "12px", my: 2 }}>
              <ErrorOutlineIcon sx={{ mr: 2 }} />
              All previous changes will be overwritten.
            </Typography>
          )}
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {arrayConnectedProviders.map((provider, index) => {
              if (!showTheWebsite && provider === "website") {
                return;
              }
              return (
                <ListItem
                  key={provider}
                  sx={{
                    pb: 2,
                    pl: 0,
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                    pt: index === 0 ? 0 : 2,
                  }}
                >
                  <ListItemIcon>
                    <Box display="flex" alignItems="center">
                      {!confirmedMode ? (
                        <Checkbox
                          disabled={
                            target === "menu" && user?.roles?.name !== "super"
                          }
                          onChange={(e) => handleChange(e, provider)}
                          defaultChecked={connectedProviders[provider]}
                          sx={{ p: 0 }}
                        />
                      ) : connectedProviders[provider] ? (
                        <CheckIcon />
                      ) : (
                        <CloseIcon />
                      )}
                      <Box sx={{ mx: 2 }}>
                        <img width="32px" src={smallIcons[provider]} />
                      </Box>
                    </Box>
                  </ListItemIcon>
                  <ListItemText primary={PROVIDERS[provider]} />
                </ListItem>
              );
            })}
          </List>
          {showTheDisabledProv && (
            <Box>
              <Typography
                sx={{
                  color: "#141414",
                  mt: 3,
                  fontSize: "12px",
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Other available Provider(s)
                <StyledTooltip
                  title={
                    <Box sx={{ fontSize: "12px" }}>
                      <Typography
                        sx={{ color: "#777777", mt: 1, fontSize: "12px" }}
                      >
                        Connect more <Link to="/providers">providers</Link> to
                        sync your menu with.
                      </Typography>
                    </Box>
                  }
                  // sx={{ ml: 2 }}
                >
                  <ErrorOutlineIcon sx={{ ml: 1, color: "#141414" }} />
                </StyledTooltip>
              </Typography>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {arrayDisconnectedProviders.map((provider, index) => {
                  return (
                    <ListItem
                      key={provider}
                      sx={{
                        pb: 2,
                        pl: 0,
                        borderBottom: (theme) =>
                          `1px solid ${theme.palette.divider}`,
                        pt: index === 0 ? 0 : 2,
                        opacity: "0.3",
                      }}
                    >
                      <ListItemIcon>
                        <Box display="flex" alignItems="center">
                          <Checkbox disabled={true} sx={{ p: 0 }} />
                          <Box sx={{ mx: 2 }}>
                            <img width="32px" src={smallIcons[provider]} />
                          </Box>
                        </Box>
                      </ListItemIcon>
                      <ListItemText primary={PROVIDERS[provider]} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            bottom: 0,
            px: 3,
            py: 2,
            left: 0,
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          {/* <Divider /> */}
          <Button
            disabled={
              isDisable ||
              loading ||
              (user?.roles?.name !== "super" && target === "menu")
            }
            onClick={handleLinkProvider}
            variant="contained"
            color="primary"
          >
            {!confirmedMode ? "Save" : "Proceed"}
          </Button>
          <Button sx={{ ml: 2 }} onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MenuLinkDrawer;
