import store from "store";
import {
  deleteProductSocket,
  getCategoryProductsV2,
  createProductSocket,
} from "redux/actions/Menu/products";
import { SOCKET_EVENT_TYPES } from "constants/sockets";
import {
  createCategory,
  updateCategory,
  deleteCategory,
  setCategoryFromSocket,
  pushCategory,
  sortCategory,
  importCategory,
} from "redux/actions/Menu/categories";
// import { getProductsAll } from 'redux/actions/Menu/products';
import { getProviders } from "redux/actions/providers";
import { getOrders, orderChangedInProvider } from "./actions/orders";
import { updateUserSession } from "redux/actions/user";
import { getMenusV2, setMenuFromSocket } from "redux/actions/Menu/menuV2";
import {
  setProductsFromSocket,
  updateProductSocket,
} from "redux/actions/Menu/products";
import { createModifier } from "./actions/Menu/modifiers";
// import { getModifiersAll } from 'redux/actions/Menu/modifiers';
import {
  ORDERS_PUSH,
  SET_LAST_UPDATED_ORDER,
  RESET_ORDERS_PAGINATION,
  UPDATE_CANCELED_ORDERS,
  SET_LAST_CANCELED_ORDER,
  SET_CURRENT_MODIFIER,
} from "redux/actions/types";
import {
  updateProviderResumePauseStatuses,
  handleProviderConnectData,
} from "redux/actions/providers";

const { dispatch, getState } = store;

export const handleSocketsEventsByType = ({ eventType, business, data }) => {
  const businessId = JSON.parse(localStorage.getItem("businessInfo"))?.id;
  if (
    business !== businessId &&
    eventType !== SOCKET_EVENT_TYPES.newOrder &&
    eventType !== SOCKET_EVENT_TYPES.orderStatusChange &&
    eventType !== SOCKET_EVENT_TYPES.orderCancel &&
    eventType !== SOCKET_EVENT_TYPES.orderChangedInProvider
  ) {
    return;
  }
  switch (eventType) {
    // Providers.
    case SOCKET_EVENT_TYPES.createProvider:
      // Need to implement in the feature
      return null;
    case SOCKET_EVENT_TYPES.updateProviderStatus: // return dispatch(getMenusV2());
      return dispatch(getProviders());
    case SOCKET_EVENT_TYPES.updateProviderState:
      return dispatch(getProviders());
    // Menu Provider.
    // case SOCKET_EVENT_TYPES.importMenuForProvider:
    //   return dispatch(getMenusV2());
    case SOCKET_EVENT_TYPES.pushMenuForProvider:
      return dispatch(setMenuFromSocket(data));
    case SOCKET_EVENT_TYPES.clearMenuForProvider:
      return dispatch(getMenusV2());
    case SOCKET_EVENT_TYPES.deleteMenuForProvider:
      return dispatch(getMenusV2());
    case SOCKET_EVENT_TYPES.updateMenuForProvider:
    // Need to implement in the feature
    // return null;
    // Categories.
    case SOCKET_EVENT_TYPES.createCategory:
      return dispatch(createCategory(data));
    case SOCKET_EVENT_TYPES.updateCategory:
      return dispatch(updateCategory(data));
    case SOCKET_EVENT_TYPES.deleteCategory:
      return dispatch(deleteCategory(data));
    case SOCKET_EVENT_TYPES.sortCategories:
      return dispatch(sortCategory(data));
    case SOCKET_EVENT_TYPES.importCategory:
      return dispatch(getMenusV2());
    case SOCKET_EVENT_TYPES.pushCategory:
      return dispatch(setCategoryFromSocket(data));
    // Products.
    case SOCKET_EVENT_TYPES.pushProduct:
      return dispatch(setProductsFromSocket(data));
    case SOCKET_EVENT_TYPES.createProduct:
      return dispatch(createProductSocket(data));
    case SOCKET_EVENT_TYPES.updateProduct:
      // dispatch(getProductsAll());
      if (!data?.category?._id) {
        return;
      }
      return dispatch(updateProductSocket(data));
    case SOCKET_EVENT_TYPES.deleteProduct:
      return dispatch(deleteProductSocket(data));
    case SOCKET_EVENT_TYPES.sortProduct:
      // dispatch(getProductsAll());
      return dispatch(getCategoryProductsV2(data.category));
    // Modifiers.
    case SOCKET_EVENT_TYPES.createModifier:
      // return dispatch(getModifiersAll());
      return dispatch(createModifier(data));
    case SOCKET_EVENT_TYPES.updateModifier:
    // return dispatch({ type: SET_CURRENT_MODIFIER, payload: data });
    // return dispatch(getModifiersAll());
    case SOCKET_EVENT_TYPES.sortModifiers:
    case SOCKET_EVENT_TYPES.deleteModifier:
    case SOCKET_EVENT_TYPES.groupModifier:
    case SOCKET_EVENT_TYPES.sortModifierList:
    case SOCKET_EVENT_TYPES.updateModifierOrder:
      // return dispatch(getModifiersAll())
      return;

    // Orders.
    case SOCKET_EVENT_TYPES.newOrder:
      dispatch({ type: SET_LAST_UPDATED_ORDER, payload: data.id });
      dispatch({ type: RESET_ORDERS_PAGINATION });
      return dispatch({ type: ORDERS_PUSH, payload: data });
    case SOCKET_EVENT_TYPES.orderStatusChange:
      return dispatch(getOrders());
    case SOCKET_EVENT_TYPES.orderChangedInProvider:
      dispatch({ type: RESET_ORDERS_PAGINATION });
      dispatch({ type: SET_LAST_UPDATED_ORDER, payload: data.id });
      return dispatch(orderChangedInProvider(data));
    case SOCKET_EVENT_TYPES.orderCancel:
      dispatch(getOrders());
      dispatch({ type: RESET_ORDERS_PAGINATION });
      dispatch({ type: SET_LAST_CANCELED_ORDER, payload: data.id });
      return dispatch({ type: UPDATE_CANCELED_ORDERS, payload: data });
    // Session.
    case SOCKET_EVENT_TYPES.updateSession:
      return dispatch(updateUserSession());
    case SOCKET_EVENT_TYPES.updateResumePauseProvider:
      dispatch(updateProviderResumePauseStatuses({ ...data }));
    case SOCKET_EVENT_TYPES.providerConnectResponse:
      // if (providerConnectData.connectionStarted) {
      dispatch(handleProviderConnectData(data));
    // }

    default:
      return null;
  }
};
