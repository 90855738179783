import React, { memo, useEffect } from "react";

import * as all from "constants/permissions";
import usePermission from "hooks/permission";
import { Box } from "@mui/material";

const disabledBackground = "rgb(255 255 255 / 46%)";

const Private = ({
  permission,
  children,
  disabled = false,
  background = disabledBackground,
}) => {
  const checkPermission = usePermission();
  useEffect(() => {
    const elem = document.getElementById("disabled");
    elem &&
      elem.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
  }, []);

  return checkPermission(all[permission]) ? (
    <>{children}</>
  ) : disabled ? (
    <Box
      sx={{
        position: "relative",
        alignItems: "center",
        padding: "5px 0",
      }}
      id="disabled"
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          background,
          zIndex: 555,
        }}
      />

      {children}
    </Box>
  ) : null;
};

export default memo(Private);
