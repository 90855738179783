import React, { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Modal,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { patchProductSoldOutById } from 'redux/actions/Menu/products';
import { patchCategorySoldOutById } from 'redux/actions/Menu/categories';
import { availabilityOptions } from 'constants/availabilityOptions';
import { confirm } from 'components/MenuNew/ConfirmModal';
import { SOLD_OUT_STATUS } from 'constants/enumSoldOut';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
};

const UpdateAvailability = (props) => {
  const { isOpen, register, setIsOpen = () => {}, availability, title, dispatch, formProduct } = props;
  const [value, setValue] = useState('');
  const [isDisable, setIsDisable] = useState(true);
  const { currentBusiness } = useSelector(({ business }) => business);
  const { onBlur, name } = register('soldOut');

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (e) => {
    setIsDisable(false);
    setValue(e.target.value);
  };

  useEffect(() => {
    if (availability.soldOut?.status) {
      availabilityOptions.map((item) => {
        if (availability.soldOut.status === item.value) {
          setValue(item.value);
        }
      });
    }
  }, [availability.soldOut?.status]);

  const handleClickOpenConfirm = async () => {
    setIsDisable(true);
    setIsOpen(false);
    const confirmed = await confirm({
      title: '',
      message: `Are you sure you want to change the ${title} availability?`,
      cancelText: 'Cancel',
      confirmText: 'Yes, Change',
      hideCloseIcon: true,
    });
    if (confirmed) {
      switch (value) {
        case SOLD_OUT_STATUS.SOLD_OUT_TODAY:
          availability.soldOut = {
            status: SOLD_OUT_STATUS.SOLD_OUT_TODAY,
          };
          break;
        case SOLD_OUT_STATUS.SOLD_OUT_PERMANENT:
          availability.soldOut = {
            status: SOLD_OUT_STATUS.SOLD_OUT_PERMANENT,
          };
          break;
        case SOLD_OUT_STATUS.AVAILABILITY:
          availability.soldOut = {
            status: SOLD_OUT_STATUS.AVAILABILITY,
          };
          break;
      }
      switch (title) {
        case 'category':
          dispatch(patchCategorySoldOutById(availability._id, availability.soldOut));
          break;
        case 'item':
          dispatch(patchProductSoldOutById(availability._id, availability.soldOut));
          break;
      }
    } else {
      if (formProduct) {
        setValue(formProduct.soldOut?.status);
      } else {
        setValue(availability?.soldOut?.status);
      }
    }
  };

  const MUIDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MUIDialogTitle onClose={handleClose}>Update {title} availability</MUIDialogTitle>
          <DialogContent
            dividers
            sx={{
              padding: '15px 20px 5px 24px',
            }}
          >
            <RadioGroup name={name} onBlur={onBlur} value={value} onChange={handleChange}>
              {availabilityOptions.map((item) => {
                return <FormControlLabel key={item.label} value={item.value} control={<Radio />} label={item.label} />;
              })}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button disabled={isDisable} sx={{ width: '100%' }} variant="contained" onClick={handleClickOpenConfirm}>
              Done
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </>
  );
};

export default memo(UpdateAvailability);
