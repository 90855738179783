import { PAGE_TITLES, SETTINGS_TITLES } from "../../constants/pageTitles";

export const getTitle = (pathName) => {
  let result;
  Object.keys(PAGE_TITLES).forEach((item) => {
    if (pathName && pathName.includes(item)) {
      if (pathName.includes("website")) {
        result = `${PAGE_TITLES.website} ${PAGE_TITLES.settings}`;
      } else {
        result = PAGE_TITLES[item];
      }
    } else if (pathName === "/" || pathName === "/Dashboards") {
      result = PAGE_TITLES.home;
    }
    return false;
  });

  Object.keys(SETTINGS_TITLES).forEach((item) => {
    if (pathName && pathName.includes(item)) {
      result = `Settings / ${SETTINGS_TITLES[item]}`;
    }
  });

  return result;
};
