import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { render, unmountComponentAtNode } from 'react-dom';
import Button from '@mui/material/Button';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

export const confirm = (props) => {
  return new Promise((resolve) => {
    let el = document.createElement('div');
    const handleResolve = (result) => {
      unmountComponentAtNode(el);
      el = null;
      resolve(result);
    };

    render(<ConfirmModal {...props} closeIcon={props.onClose} onClose={handleResolve} />, el);
  });
};

const ConfirmModal = ({
  title = '',
  onClose,
  message = 'Are you sure you want to cancel the changes before leaving?',
  className,
  cancelText = 'Cancel',
  confirmText = 'Save and Leave',
  hideCloseIcon = false,
  // open = true,
  closeIcon,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = (val) => {
    if (typeof val !== 'boolean') {
      return;
    }
    return onClose(val);
  };

  closeIcon = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { p: 3 } }}>
      {!hideCloseIcon && (
        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} aria-label="close" onClick={() => closeIcon()}>
          <CloseIcon />
        </IconButton>
      )}
      <DialogTitle sx={{ textAlign: 'center', p: 0 }}>{title}</DialogTitle>
      <Typography sx={{ fontSize: '14px', fontWeight: 500, textAlign: 'center', color: '#3D4350', mt: 1 }}>
        {message}
      </Typography>
      <Stack justifyContent="center" direction="row" sx={{ mt: 4 }}>
        <Button variant="contained" onClick={() => handleClose(true)}>
          {confirmText}
        </Button>
        <Button sx={{ ml: 2, color: 'black' }} onClick={() => handleClose(false)}>
          {cancelText}
        </Button>
      </Stack>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

ConfirmModal.defaultProps = {
  message: 'Are you sure?',
  title: 'Are you sure?',
  confirmText: 'Ok',
  cancelText: '',
  confirmColor: 'primary',
  cancelColor: '',
  className: '',
  buttonsComponent: null,
  size: null,
};

export default ConfirmModal;
