import React from 'react';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CategoryForm from '../FormsView/CategoryForm/index';

export const FormCategoryPage = () => {
  const history = useHistory();
  const model = history.location.state;
  return (
    <Box sx={{ width: '100%', background: 'white', height: 'calc(100vh - 152px)' }}>
      {/* <Box sx={{ p: 3, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
        <Typography>
          <KeyboardBackspaceIcon sx={{ mr: 2, cursor: 'pointer' }} onClick={() => history.push('categories')} />
          {model?.name}
        </Typography>
      </Box>
      <Box sx={{ width: '50%', p: 3 }}>
        <CategoryForm showAction={true} />
      </Box> */}
      <CategoryForm showAction={true} />
    </Box>
  );
};

export default FormCategoryPage;
