const startStopInitValues = {
  duration: [
    {
      text: '1 hour',
      value: 1,
    },
    {
      text: '2 hours',
      value: 2,
    },
    {
      text: 'Today',
      value: 'today',
    },
    {
      text: 'Permanent',
      value: 'permanent',
    },
  ],
  reason: [
    {
      text: 'Restaurant is busy',
      value: 'Restaurant is busy',
    },
    {
      text: 'Out of inventory',
      value: 'Out of inventory',
    },
    {
      text: 'Restaurant problem',
      value: 'Restaurant problem',
    },
    {
      text: 'Other',
      value: 'Other',
    },
  ],
  action: [
    {
      text: 'Pause Orders',
      value: 'pause',
    },
    {
      text: 'Resume Orders',
      value: 'resume',
    },
  ],
};

export default startStopInitValues;
