import { SET_REPORT_LIST, SET_REPORT_LOADING, SET_REPORT_FILTERS, ADD_REPORT_FILTERS } from 'redux/actions/types';
const initialState = {
  reportList: [],
  filterList: [],
  reportId: null,
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_REPORT_LIST:
      return {
        ...state,
        reportList: payload.data,
      };
    case SET_REPORT_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_REPORT_FILTERS:
      return {
        ...state,
        filterList: payload.data,
      };
    // case ADD_REPORT_FILTERS:
    //   return {
    //     ...state,
    //     filterList: [...state.filterList, payload.data],
    //   };
    default:
      return state;
  }
};
