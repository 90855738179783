import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ocoPost, ocoPatch, osoPatchUrl } from 'helpers/googleTagManager';
import axios from 'axios';

const GoogleTagWidget = ({ children }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const utm_source = searchParams.get('utm_source');
  const utm_medium = searchParams.get('utm_medium');
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_content = searchParams.get('utm_content');
  const kw = searchParams.get('kw');
  const mtype = searchParams.get('mtype');
  const network = searchParams.get('network');
  const device = searchParams.get('device');
  const kwid = searchParams.get('kwid');
  const gclid = searchParams.get('gclid');
  const model = searchParams.get('model');
  const position = searchParams.get('position');
  const test = searchParams.get('test');

  useEffect(() => {
    // let oco_profile_id = localStorage.getItem('oco_profile_id');
    // if (!oco_profile_id) {
    //   axios.post(osoPatchUrl).then((res) => {
    //     if (res.data.oco_profile_id) {
    //       oco_profile_id = res.data.oco_profile_id;
    //       localStorage.setItem('oco_profile_id', oco_profile_id);
    //     }
    //   });
    // }
    if (utm_source) {
      const formJsonModel = {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        kw,
        mtype,
        network,
        device,
        kwid,
        gclid,
        model,
        position,
        test,
      };
      ocoPost(formJsonModel);
      // ocoPatch(formJsonModel, oco_profile_id);
    }
  }, []);

  return <div>{children}</div>;
};

export default GoogleTagWidget;
