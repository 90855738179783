import { SET_CHECKED_PROVIDER } from 'redux/actions/types';

export const showPriceRange = (product) => {
  let result = `$${parseFloat(product?.price).toFixed(2)}`;
  if (product?.isOnePrice) {
    result = `$${parseFloat(product?.price).toFixed(2)}`;
  } else if (product?.minPrice || product?.minPrice === 0) {
    result = `$${parseFloat(product?.minPrice).toFixed(2)} - $${parseFloat(product?.maxPrice).toFixed(2)}`;
  }
  return result;
};
