import { MENU_TYPES } from 'constants/menu';

export const linkedWithProvider = (connectedProviders) => {
  const result = Object.keys(connectedProviders)?.filter((item) => {
    return item != MENU_TYPES.main && connectedProviders[item];
  })?.length;
  return Boolean(result);
  // const moreThenOneConnected = Object.values(menu.connectedProviders).filter((item) => item);
};

export const checkLinkedProviderWebsite = (connectedProviders) => {
  const result = Object.keys(connectedProviders)?.filter((item) => {
    return item != MENU_TYPES.main && item != MENU_TYPES.website && connectedProviders[item];
  })?.length;
  return Boolean(result);
};
