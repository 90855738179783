import React, { useState, memo, useCallback, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { smallIcons } from "services/serviceIcons";
import { v4 as uuidv4 } from "uuid";
import CustomTextInput from "components/Forms/CustomTextInput";
import Popover from "@mui/material/Popover";
import ListItemText from "@mui/material/ListItemText";
import { Typography, List, ListItem, Button } from "@mui/material";
import { CONTEXT_TYPES, MENU_TYPES, PROVIDERS } from "constants/menu";
import Icon from "@mui/material/Icon";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import lock from "assets/icons/lock.png";
import unlock from "assets/icons/unlock.png";

const PriceOverridePopover = ({
  priceOverride,
  itemIndex,
  connectedProviders,
  target,
  providerIndex,
  handleReset = () => {},
  handleClean = () => {},
  onChangePrice,
  errors = {},
  isSubmitted,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [disabledPriceField, handleDisablePriceField] = useState({});
  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const billingPlan = useSelector(({ business }) => business);
  const showTheWebsite =
    billingPlan?.currentBusiness?.billingInfo?.plan?.name !== "App Bundle";
  const filteredPrice = useMemo(
    () =>
      priceOverride?.filter(
        (item) =>
          item.context_type === CONTEXT_TYPES?.provider &&
          connectedProviders?.[item.context_value]
      ),
    [priceOverride, target, connectedProviders]
  );

  useEffect(() => {
    if (isSubmitted) {
      handleDisablePriceField({});
    }
  }, [isSubmitted]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const getIndex = useCallback(
    (context_value) => {
      const index =
        priceOverride?.length &&
        priceOverride?.findIndex(
          (item) => item.context_value === context_value
        );
      return index;
    },
    [filteredPrice, itemIndex]
  );

  const haveConnected = useMemo(() => {
    if (connectedProviders) {
      return (
        Object.values(connectedProviders).filter((item) => item).length > 1
      );
    }
    return false;
  }, [connectedProviders, priceOverride]);

  const handleSave = () => {
    setAnchorEl(null);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleToReset = () => {
    handleClosePopover();
    handleReset();
  };
  const handleToClean = () => {
    handleClosePopover();
    handleClean();
  };

  const handleEnableChangePrice = (data, lock) => {
    handleDisablePriceField({
      ...disabledPriceField,
      [data]: !disabledPriceField[data],
    });
  };

  const findIndex = priceOverride?.findIndex(
    (item) => item.context_value === MENU_TYPES.ubereats
  );
  const checkError = Object.keys(errors).length;
  const sizeTypeModifiersErr =
    errors?.sizeTypeModifiers?.[0]?.list[itemIndex]?.priceOverride;
  const productFormPriceErr =
    target === "productFormPricePopover" && errors?.priceOverride?.[findIndex];
  const errorColorForUber =
    checkError && (sizeTypeModifiersErr || productFormPriceErr)
      ? "#d32f2f"
      : "#3D4350";

  const showLockIcon =
    target === "productFormPricePopover" ||
    target === "productFormSizeTypePricePopover";

  return (
    <Box>
      <Box
        onClick={handleClickPopover}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "22px",
          ml: 1,
          borderRadius: "100px",
          cursor: "pointer",
          justifyContent: "center",
        }}
      >
        <Icon
          sx={{
            color: errorColorForUber,
            fontSize: "14px",
          }}
          component={SettingsOutlinedIcon}
        />
      </Box>

      <Popover
        id={"simple-popover"}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper sx={{ px: 3, py: 2 }}>
          <List>
            <ListItem
              key={uuidv4()}
              sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                px: 0,
                pt: 0,
                pb: 2,
              }}
            >
              <Typography
                sx={{ color: "#9E9EAD", fontSize: "14px", fontWeight: 500 }}
              >
                Providers
              </Typography>
            </ListItem>

            {haveConnected ? (
              filteredPrice?.map((item, index) => {
                if (
                  item.context_value === "website" &&
                  !showTheWebsite &&
                  billingPlan.currentBusiness?.billingInfo
                ) {
                  return;
                }
                let name = "";

                if (target === "productFormPricePopover") {
                  name = `priceOverride[${getIndex(item.context_value)}].price`;
                }

                if (target === "productFormSizeTypePricePopover") {
                  name = `sizeTypeModifiers[0].list[${itemIndex}].priceOverride[${getIndex(
                    item.context_value
                  )}].price`;
                }

                if (target === "modGroupPricePopover") {
                  name = `list[${itemIndex}].priceOverride[${index}].price`;
                }

                if (target === "modGroupSizeTypePricePopover") {
                  name = `list[${itemIndex}].priceOverride[${providerIndex}].priceOverride[${index}].price`;
                }

                return (
                  item.context_value != MENU_TYPES.main && (
                    <ListItem
                      key={item.id || item._id || uuidv4()}
                      sx={{
                        px: 0,
                        mx: 0,
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        width="100%"
                      >
                        <Box display="flex" alignItems="center">
                          <img
                            width="32px"
                            src={smallIcons[item?.context_value]}
                          />
                          <Typography
                            sx={{
                              color: "#3D4350",
                              fontSize: "12px",
                              fontWeight: 400,
                              ml: "12px",
                            }}
                          >
                            {PROVIDERS[item?.context_value]}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "150px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CustomTextInput
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            name={name}
                            label="Price"
                            size="small"
                            inputProps={{ type: "number" }}
                            sx={{ width: "120px" }}
                            disabled={
                              item.context_value !== MENU_TYPES.website &&
                              !disabledPriceField[item.context_value]
                            }
                            onChange={onChangePrice}
                          />
                          {item.context_value != MENU_TYPES.website &&
                            showLockIcon && (
                              <Box
                                sx={{ width: "24px", ml: 1, cursor: "pointer" }}
                                onClick={() =>
                                  handleEnableChangePrice(
                                    item.context_value,
                                    true
                                  )
                                }
                              >
                                <img
                                  style={{ width: "100%" }}
                                  alt="lock"
                                  src={
                                    !disabledPriceField[item.context_value]
                                      ? lock
                                      : unlock
                                  }
                                />
                              </Box>
                            )}
                        </Box>
                      </Box>
                    </ListItem>
                  )
                );
              })
            ) : (
              <ListItemText primary="There is no any connected provider" />
            )}
            <ListItem
              sx={{
                borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                px: 0,
                pb: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Button
                    disabled={!haveConnected}
                    onClick={handleSave}
                    variant="contained"
                  >
                    <CheckIcon />
                  </Button>
                  <Button
                    disabled={!haveConnected}
                    onClick={handleToClean}
                    sx={{ background: "#F2F2F2", color: "black", ml: 1 }}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Button
                    disabled={!haveConnected}
                    onClick={handleToReset}
                    sx={{ color: "black", fontSize: "14px" }}
                  >
                    Reset To Default
                  </Button>
                </Box>
              </Box>
            </ListItem>
          </List>
        </Paper>
      </Popover>
    </Box>
  );
};

export default memo(PriceOverridePopover);
