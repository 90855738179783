import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useFormContext, Controller } from 'react-hook-form';

export default function MultipleSelectChip({ options, name, label, formProps, defaultValues = [], sx, ...params }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControl {...params} size="small" sx={sx}>
            <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              multiple
              defaultValue={defaultValues}
              value={field.value?.map((item) => (typeof item === 'string' ? item : item?._id))}
              onChange={field.onChange}
              input={<OutlinedInput id="select-multiple-chip" label="Classifications" />}
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={options?.find((e) => e._id === value)?.name} />
                    ))}
                  </Box>
                );
              }}
            >
              {options.map((data) => {
                return (
                  <MenuItem key={data?._id} value={data._id}>
                    <ListItemText primary={data.name} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }}
    />
  );
}
