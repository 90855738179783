import { toast } from 'react-toastify';
import instance from '../../services/api';
import { gatewayInstance } from '../../services/api';
import { getMenus } from './Menu/menu';

export const getProviders = () => (dispatch, getState) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  return instance
    .get('/providers')
    .then(({ data: { data } }) => {
      return dispatch({
        type: 'PROVIDERS_RES',
        payload: { items: Object.values(data.providers), providerId: data._id },
      });
    })
    .catch(() => {
      dispatch({ type: 'PROVIDERS_ERR' });
    });
};

export const getProviderStatus = (provider) => (dispatch) => {
  return instance.get(`/providers/${provider}/status`).then(({ data: { data } }) => {
    return data;
  });
};
export const handleProviderConnectData = (data) => (dispatch) => {
  return dispatch({ type: 'PROVIDER_CONNECT_DATA_RESPONSE', payload: data });
};

export const connectStoreProviderUber = (body) => (dispatch, getState) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  const { providers } = getState();

  return gatewayInstance
    .post('ubereats/provider/connect/', {
      ...body,
      redirectUri: process.env.REACT_APP_API_PROD_URL,
    })
    .then((res) => {
      const {
        data: {
          body: {
            providers: {
              ubereats: { connected, state, status, srv },
            },
          },
        },
      } = res;

      const updateUberObj = {
        enabled: connected,
        id: providers?.providerId,
        provider: srv,
        state: state,
        status: status,
      };
      return dispatch(updateProvider(updateUberObj));
    })
    .catch((err) => {
      console.log('err: ', err);
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const connectStoreProvider = (body) => (dispatch, getState) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  const { providers } = getState();
  const copyProviders = [...providers.items];
  return instance
    .post('providers/connect/', {
      ...body,
    })
    .then((res) => {
      const {
        data: { data },
      } = res;
      return dispatch({
        type: 'PROVIDERS_RES',
        payload: { items: Object.values(data.providers) },
      });
    })
    .catch((err) => {
      toast.error('Error Connect Provider');
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const connectProviderUber = (code, state) => (dispatch, getState) => {
  const { providers } = getState();
  const copyProviders = [...providers.items];
  dispatch({ type: 'PROVIDERS_REQ' });
  return gatewayInstance
    .post(`ubereats/provider/connect/`, {
      code,
      state,
      redirectUri: process.env.REACT_APP_API_PROD_URL,
    })
    .then((res) => {
      const {
        data: { body },
      } = res;
      copyProviders.forEach((item, index) => {
        if (item.srv === body.providers.ubereats.srv) {
          copyProviders[index] = { ...body.providers.ubereats };
        }
      });
      dispatch({
        type: 'PROVIDERS_RES',
        payload: providers,
      });
      return body;
    })
    .catch((err) => {
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

// export const disconnectProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/disconnect/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

// export const enableProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/enable/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

// export const disableProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/disable/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

// export const settingsProviderUber = (body) => (dispatch) => {
//   return instance
//     .post('/provider/settings/{owner}', body)
//     .then(({ data: { data } }) => {
//       return dispatch({
//         type: 'PROVIDERS_RES',
//         payload: { items: Object.values(data.providers), providerId: data._id },
//       });
//     })
//     .catch((err) => {
//       dispatch({ type: 'PROVIDERS_ERR' });
//       return Promise.reject(err);
//     });
// };

export const connectProviderLocation = (body) => (dispatch) => {
  return instance
    .post('/providers/connect', body)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const disconnectProvider = (id) => (dispatch) => {
  dispatch({ type: 'PROVIDERS_REQ' });
  return instance
    .get(`/providers/disconnect/${id}`)
    .then(({ data: { data } }) => {
      dispatch(getMenus());
      return dispatch({
        type: 'PROVIDERS_RES',
        payload: { items: Object.values(data.providers), providerId: data._id },
      });
    })
    .catch((err) => {
      dispatch({ type: 'PROVIDERS_ERR' });
      return Promise.reject(err);
    });
};

export const updateProvider = (body) => (dispatch) => {
  return instance.put('/providers/status', body);
};

export const updateProviderState = (body) => (dispatch) => {
  return instance.put('/providers/state', body).then(() => {
    // dispatch(getProviders());
  });
};

export const updateProviderConfirmOrder = (body) => (dispatch) => {
  return instance.put('/providers/confirm-order', body).then(() => {
    dispatch(getProviders());
  });
};

export const updateStopResumeOrders = (data) => (dispatch) => {
  dispatch({ type: 'PROVIDERS_START_RESUME', payload: data });
};

export const updateProviderResumePauseStatuses = (data) => (dispatch) => {
  dispatch({ type: 'UPDATE_PROVIDER_RESUME_PAUSE_STATUSES', payload: data });
};

export const stopProvider = () => (dispatch, getState) => {
  dispatch(updateStopResumeOrders({ loading: true }));
  const {
    providers: { stopResumeOrders },
  } = getState();
  const { provider, data } = stopResumeOrders;
  const { duration, reason } = data;
  const dataCopy = { ...data };
  if (dataCopy.duration === 'today') {
    dataCopy.duration = 0;
    dataCopy.today = true;
    dataCopy.permanent = false;
  }
  if (dataCopy.duration === 'permanent') {
    dataCopy.duration = 0;
    dataCopy.today = false;
    dataCopy.permanent = true;
  }
  // Stop/Start taking orders request has been failed for <provider>. Please go to Provider section to send the request again.“
  return instance
    .post(`/providers/suspension/pause/${provider}`, dataCopy)
    .then((res) => {
      const { data } = res;
      dispatch(getProviders());
      dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
      if (provider === 'all') {
        if (!Boolean(data.data.grubhub)) {
          toast.error('Stop taking orders request has been failed for Grubub.');
        }
        if (!Boolean(data.data.ubereats)) {
          toast.error('Stop taking orders request has been failed for UberEats.');
        }
        if (!Boolean(data.data.doordash)) {
          toast.error('Stop taking orders request has been failed for Doordash.');
        }
      } else {
        if (!Boolean(data.data[provider])) {
          toast.error(`Stop taking orders request has been failed for ${provider}.`);
        }
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data.message);
    })
    .finally(() => {
      dispatch(updateStopResumeOrders({ loading: false, showModal: false }));
      dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
    });
};

export const resumeProvider = () => (dispatch, getState) => {
  dispatch(updateStopResumeOrders({ loading: true }));

  const {
    providers: { stopResumeOrders },
  } = getState();
  const { provider } = stopResumeOrders;

  instance
    .post(`/providers/suspension/resume/${provider}`)
    .then((res) => {
      dispatch(getProviders());
      dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
      const { data } = res;
      if (provider === 'all') {
        if (!Boolean(data.data.grubhub)) {
          toast.error('Start taking orders request has been failed for Grubub.');
        }
        if (!Boolean(data.data.ubereats)) {
          toast.error('Start taking orders request has been failed for UberEats.');
        }
        if (!Boolean(data.data.doordash)) {
          toast.error('Start taking orders request has been failed for Doordash.');
        }
      } else {
        if (!Boolean(data.data[provider])) {
          toast.error(`Start taking orders request has been failed for ${provider}.`);
        }
      }
    })
    .catch((err) => {
      toast.error(err?.response?.data.message);
    })
    .finally(() => {
      dispatch(updateStopResumeOrders({ loading: false }));
      dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
    });
};
