import axios from "axios";
import store from "../store";
import { toast } from "react-toastify";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const authInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_AUTH,
});

export const gatewayInstance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
});

export const reportingInstance = axios.create({
  baseURL: process.env.REACT_APP_REPORTING_URL,
});

export const setInstance = (token) => {
  localStorage.setItem("accessToken", token);
  const businessId = JSON.parse(localStorage.getItem("businessInfo"))?.id;

  reportingInstance.defaults.headers["app-v"] = `0.1.0`;
  reportingInstance.defaults.headers.Authorization = `Bearer ${token}`;
  reportingInstance.defaults.headers.business = businessId;

  gatewayInstance.defaults.headers["app-v"] = `0.1.0`;
  gatewayInstance.defaults.headers.Authorization = `Bearer ${token}`;
  gatewayInstance.defaults.headers.business = businessId;

  instance.defaults.headers["app-v"] = `0.1.0`;
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  instance.defaults.headers.business = businessId;

  authInstance.defaults.headers["app-v"] = `0.1.0`;
  authInstance.defaults.headers.Authorization = `Bearer ${token}`;
  authInstance.defaults.headers.business = businessId;
};

export const removeInstance = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("business");
  localStorage.removeItem("businessInfo");
  instance.defaults.headers.Authorization = "";
};

const storedToken = localStorage.getItem("accessToken");

if (storedToken) {
  setInstance(storedToken);
}

instance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.success(response.data.message);
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch({ type: "USER_LOGOUT" });
      removeInstance();
    }
    if (error.config.method !== "get" && error.response?.data?.message) {
      let message = error.response.data.message;
      if (error.response.data.errors) {
        message += `\n ${Object.values(error.response.data.errors)
          .map(({ msg }) => msg)
          .join(`\n`)}`;
      }
      if (error.config.url !== "/auth/login") {
        toast.error(message);
      }
    }
    return Promise.reject(error);
  }
);

authInstance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.success(response.data.message);
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch({ type: "USER_LOGOUT" });
      removeInstance();
    }
    if (error.config.method !== "get" && error.response?.data?.message) {
      let message = error.response.data.message;
      if (error.response.data.errors) {
        message += `\n ${Object.values(error.response.data.errors)
          .map(({ msg }) => msg)
          .join(`\n`)}`;
      }
      if (error.config.url !== "/auth/login") {
        toast.error(message);
      }
    }
    return Promise.reject(error);
  }
);

reportingInstance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.success(response.data.message);
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch({ type: "USER_LOGOUT" });
      removeInstance();
    }
    if (error.config.method !== "get" && error.response?.data?.message) {
      let message = error.response.data.message;
      if (error.response.data.errors) {
        message += `\n ${Object.values(error.response.data.errors)
          .map(({ msg }) => msg)
          .join(`\n`)}`;
      }
      if (error.config.url !== "/auth/login") {
        toast.error(message);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
