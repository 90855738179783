import { availabilityOptions } from 'constants/availabilityOptions';
import { SOLD_OUT_STATUS } from 'constants/enumSoldOut';

export const filteringSoldOut = (data) => {
  let value = '';
  if (data.soldOut?.status) {
    availabilityOptions.map((item) => {
      if (data.soldOut.status === item.value) {
        value = item.value;
      }
    });
  }
  return value;
};

export const renderPermanently = (data, moment) => {
  const isToday = filteringSoldOut(data) === SOLD_OUT_STATUS.SOLD_OUT_TODAY;
  const isUntil = filteringSoldOut(data) === SOLD_OUT_STATUS.SOLD_OUT_PERMANENT;
  let result = '';
  if (isToday) {
    result = moment(data.soldOut.soldOutDate)?.format('MMMM D, YYYY');
  } else if (isUntil) {
    result = 'Permanently unavailable';
  }
  return result;
};
