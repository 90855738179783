export const SOLD_OUT_ENUM = {
  availability: 'Available',
  soldOutToday: 'Sold out for today',
  soldOutPermanent: 'Sold out until I make it available',
};

export const SOLD_OUT_STATUS = {
  AVAILABILITY: 'available',
  SOLD_OUT_TODAY: 'soldOutToday',
  SOLD_OUT_PERMANENT: 'soldOutPermanent',
};
