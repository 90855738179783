import { SET_EVENT_LIST, SET_EVENT_DEFAULT, SET_EVENT_LIST_LOADING, SET_EVENT_ACTIONS } from 'redux/actions/types';

const mockEventList = [
  {
    id: 111,
    type: {
      name: 'EventList1',
      _id: '1',
    },
    action: {
      name: 'Action1',
      _id: '1',
    },
    sendTo: 'Fax',
    copy: 1,
    active: true,
  },
  {
    id: 222,
    type: {
      name: 'EventList2',
      _id: '2',
    },
    action: {
      name: 'Action2',
      _id: '2',
    },
    sendTo: 'mail',
    copy: 2,
    active: false,
  },
  {
    id: 333,
    type: {
      name: 'EventList3',
      _id: '3',
    },
    action: {
      name: 'Action3',
      _id: '3',
    },
    sendTo: 'printer',
    copy: 2,
    active: false,
  },
];

const mockEventsDefault = [
  {
    _id: '1',
    name: 'Event1',
  },
  {
    _id: '2',
    name: 'Event2',
  },
  {
    _id: '3',
    name: 'Event3',
  },
  {
    _id: '4',
    name: 'Event4',
  },
];

const mockEventActions = [
  {
    _id: '1',
    name: 'Type1',
  },
  {
    _id: '2',
    name: 'Type2',
  },
  {
    _id: '3',
    name: 'Type3',
  },
];

const initialState = {
  eventList: [],
  eventDefault: [],
  eventActions: [],
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EVENT_LIST:
      return {
        ...state,
        eventList: payload.data,
      };
    case SET_EVENT_DEFAULT:
      return {
        ...state,
        eventDefault: payload.data,
      };
    case SET_EVENT_ACTIONS:
      return {
        ...state,
        eventActions: payload.data,
      };  
    case SET_EVENT_LIST_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};
