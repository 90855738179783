import React from 'react';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import ModifierGroupForm from '../FormsView/NewModifierGroup/index';

export const FormModifierPage = ({ currentModifier }) => {
  return (
    <Box sx={{ background: 'white', height: '100%' }}>
      <ModifierGroupForm currentModifier={currentModifier} isOnePrice={true} />
    </Box>
  );
};

export default FormModifierPage;
