import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Icon from "@mui/material/Icon";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { v4 as uuidv4 } from "uuid";
import { smallIcons } from "services/serviceIcons";
import ellipseOn from "assets/icons/EllipseOn.png";
import ellipseOff from "assets/icons/EllipseOff.png";
import { PROVIDER_CONNECTED } from "constants/providerStatuses";
import { PROVIDERS } from "constants/menu";
import {
  SET_LINK_PROVIDER_VISIBILITY,
  SET_LINK_PROVIDER,
} from "redux/actions/types";
import Private from "components/Private";

const LinkedProviderPopover = ({
  connectedProviders,
  target,
  id,
  showCount = true,
  targetConnectedProviders,
  categoryId,
  disabled,
  overrideStyle = {},
}) => {
  const { items } = useSelector(({ providers }) => providers);

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const billingPlan = useSelector(({ business }) => business);

  const showTheWebsite = useMemo(() => {
    return (
      billingPlan?.currentBusiness?.billingInfo?.plan?.name !== "App Bundle" &&
      targetConnectedProviders?.website
    );
  }, [targetConnectedProviders, connectedProviders]);

  const handleClickPopover = (event) => {
    // conditionForDisableLink && setAnchorEl(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openLinkPage = () => {
    dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: true });
    dispatch({
      type: SET_LINK_PROVIDER,
      payload: {
        connectedProviders,
        targetConnectedProviders,
        target,
        id,
        categoryId,
      },
    });
    setAnchorEl(null);
  };
  const connectedCount = useMemo(() => {
    return (
      Object.values(connectedProviders || {})?.filter((item, index) => {
        if (
          (Object.keys(connectedProviders)[index] !== "website" &&
            !showTheWebsite) ||
          !billingPlan.currentBusiness?.billingInfo ||
          showTheWebsite
        ) {
          return item;
        }
      }).length - 1
    );
  }, [connectedProviders]);

  const arrayFromProviders = useMemo(() => {
    const array = items
      .filter(
        (item) =>
          item.status === PROVIDER_CONNECTED &&
          targetConnectedProviders &&
          targetConnectedProviders[item.srv] &&
          item.srv !== "postmates"
      )
      .map((item) => item.srv);
    if (
      targetConnectedProviders &&
      targetConnectedProviders["website"] &&
      (showTheWebsite || !billingPlan.currentBusiness?.billingInfo)
    ) {
      array.push("website");
    }
    return array;
  }, [connectedProviders, targetConnectedProviders]);

  const openPopover = Boolean(anchorEl);
  const allConnected = connectedCount == arrayFromProviders.length;
  let color = "#3D4350";
  let iconComponent = InsertLinkIcon;
  if (!allConnected && target === "item") {
    color = "#EF3061";
    iconComponent = LinkOffIcon;
  }
  if (connectedCount === 0 && target === "category") {
    color = "#EF3061";
    iconComponent = LinkOffIcon;
  }
  return (
    <Box>
      <Private permission="MENU_EDIT" disabled={true} background="none">
        <IconButton
          onClick={handleClickPopover}
          disabled={disabled}
          sx={{
            display: "flex",
            alignItems: "center",
            width: "43px",
            p: 0,
            height: "22px",
            ml: 2,
            background: showCount ? "#E2E2E2" : "none",
            borderRadius: "100px",
            cursor: "pointer",
            justifyContent: "center",
            ...overrideStyle,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Icon
              sx={{
                color: disabled ? "text.disabled" : color,
              }}
              component={iconComponent}
            />
            {showCount && connectedCount !== 0 ? (
              <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                {connectedCount}
              </Typography>
            ) : null}
          </Box>
        </IconButton>
      </Private>

      <Popover
        id={"simple-popover"}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper sx={{ width: "300px", p: 3 }}>
          <List>
            {arrayFromProviders.length
              ? arrayFromProviders.map((provider, index) => {
                  return (
                    provider?.length && (
                      <ListItem
                        key={uuidv4()}
                        sx={{
                          pb: 2,
                          borderBottom: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                          pt: index === 0 ? 0 : 2,
                        }}
                      >
                        <ListItemIcon>
                          <Box display="flex" alignItems="center">
                            <img
                              src={
                                connectedProviders?.[provider]
                                  ? ellipseOn
                                  : ellipseOff
                              }
                            />
                            <Box sx={{ mx: 2 }}>
                              <img width="32px" src={smallIcons[provider]} />
                            </Box>
                          </Box>
                        </ListItemIcon>
                        <ListItemText primary={PROVIDERS[provider]} />
                      </ListItem>
                    )
                  );
                })
              : "There is no any provider to link"}
          </List>

          <Typography sx={{ color: "#777777", fontSize: "12px" }}>
            Go to{" "}
            <span
              style={{ color: "#2D7FF9", cursor: "pointer" }}
              onClick={openLinkPage}
            >
              Configuration
            </span>{" "}
            to sync your menu, categories and products with connected providers
          </Typography>
        </Paper>
      </Popover>
    </Box>
  );
};

export default LinkedProviderPopover;
