// Also need to add for home, report page,

const MENU_VIEW = 2;
const MENU_EDIT = 4;
const ORDER_VIEW = 8;
const ORDER_EDIT = 16;
const PROVIDERS_VIEW = 32;
const PROVIDERS_EDIT = 64;
const SETTINGS_ACCOUNT = 128;
const SETTINGS_WEBSITE = 256;
const SETTINGS_BUSINESS = 512;
const SETTINGS_USERS = 1024;
const SETTINGS_BILLING = 2048;
const BATCH_REPORT = 4096;
const HOME_REPORT_FILTER = 8192;
const SETTINGS_ADMIN = 16384;
const SCHEDULE_READ = 32768;
const SCHEDULE_EDIT = 65536;
const SETTINGS_PRINTERS = 131072;
const SETTINGS_EVENTS = 262144;
const SETTINGS_BRAND_LOCATIONS = 524288;
const SETTINGS_ADD_LOCATION = 1048576;

const UNCONFIRMED = 1;
const PENDING = 2;
const ACTIVE = 3;

const SUPER = 4194302;
const ADMIN = 4177918;
const MANAGER = 505854;
const LOCATION_ADMIN = 2078718;
const USER = 32922;

const APP_BUNDLE = 2;
const WEB_BUNDLE = 4;
const PREMIUM = 14;

export {
  MENU_VIEW,
  MENU_EDIT,
  ORDER_VIEW,
  ORDER_EDIT,
  BATCH_REPORT,
  APP_BUNDLE,
  UNCONFIRMED,
  WEB_BUNDLE,
  PREMIUM,
  PROVIDERS_VIEW,
  PROVIDERS_EDIT,
  SETTINGS_ACCOUNT,
  SETTINGS_USERS,
  SETTINGS_BILLING,
  SETTINGS_PRINTERS,
  SETTINGS_EVENTS,
  SETTINGS_BRAND_LOCATIONS,
  SETTINGS_ADD_LOCATION,
  SETTINGS_WEBSITE,
  SETTINGS_BUSINESS,
  HOME_REPORT_FILTER,
  SETTINGS_ADMIN,
  SCHEDULE_READ,
  SCHEDULE_EDIT,
  PENDING,
  ACTIVE,
  ADMIN,
  SUPER,
  USER,
  MANAGER,
  LOCATION_ADMIN,
};
