import { USER_LOADING, USER_LOADED, USER_LOGOUT } from '../../constants/user';
import { SET_CURRENT_BUSINESSES, SET_CURRENT_BUSINESSES_DISABLE, SET_CURRENT_BUSINESSES_ENABLE } from './types';
import instance, { setInstance, removeInstance, authInstance } from '../../services/api';
// import { getMenusV2 } from './menuV2';
import { saveFormDataWindowLayer, ocoPatch } from 'helpers/googleTagManager';
import { getMenusV2 } from './Menu/menuV2';

export const signup = (body) => {
  return instance.post('/auth/register', { ...body }).then(() => {
    if (body) {
      const saveFormDataWindowLayerModel = {
        event: 'signupSubmit',
        firstName: body.firstName || '',
        lastName: body.lastName || '',
        email: body.email || '',
        phone: body.phone || '',
        restaurantName: body.companyName || '',
        businessPhone: body.businessPhone || '',
      };
      ocoPatch(saveFormDataWindowLayerModel);
      saveFormDataWindowLayer(saveFormDataWindowLayerModel);
    }
  });
};

export const superSignIn = (body) => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: true });
  return instance
    .post('/auth/super-login', body)
    .then(({ data: { data } }) => {
      localStorage.setItem(
        'businessInfo',
        JSON.stringify({
          label: `${data.user.business[0].companyName} - ${
            data.user.business[0]?.address || data.user.business[0].addressInfo?.street1 || ''
          }`,
          id: data.user.business[0].id,
        })
      );
      setInstance(data.token);
      dispatch({ type: USER_LOADED, payload: { data: data.user, authorized: true, loading: false } });
      return data.user;
    })
    .catch((err) => {
      dispatch({ type: USER_LOADING, payload: false });
      return Promise.reject(err);
    });
};

export const signIn = (body) => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: true });

  return authInstance
    .post('/login', body)
    .then(({ data: { data } }) => {
      if (body) {
        const saveFormDataWindowLayerModel = {
          email: body.email || '',
          event: 'loginSuccess',
        };
        ocoPatch(saveFormDataWindowLayerModel);
        saveFormDataWindowLayer(saveFormDataWindowLayerModel);
      }
      if (Boolean(data.user.business[0])) {
        localStorage.setItem(
          'businessInfo',
          JSON.stringify({
            label: `${data.user.business[0].companyName} - ${
              data.user.business[0].address
                ? data.user.business[0].address || data.user.business[0].addressInfo.street1
                : ''
            }`,
            id: data.user.business[0].id,
          })
        );
      }

      setInstance(data.token);
      dispatch({ type: USER_LOADED, payload: { data: data.user, authorized: true, loading: false } });
      return data.user;
    })
    .catch((err) => {
      dispatch({ type: USER_LOADING, payload: false });
      return Promise.reject(err);
    });
};

export const forgotPassword = (body) => {
  return instance
    .post('/auth/forgot', body)
    .then(({ data: { data } }) => {
      setInstance(data.token);
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const logout = () => (dispatch) => {
  authInstance.get('/logout').then(() => {
    dispatch({ type: 'RESET_MENUS' });
  });
  dispatch({ type: USER_LOGOUT });
  removeInstance();
};

export const inviteUser = (body) => {
  return instance.post('/auth/create-user', body);
};

export const signupUser = (body) => (dispatch) => {
  return instance.post('/auth/complete-signup', body).then(({ data: { data } }) => {
    setInstance(data.token);
    dispatch({ type: USER_LOADED, payload: { data: data.user, authorized: true, loading: false } });
    window.location.href = '/';
    return data.user;
  });
};

export const getUserData = () => (dispatch) => {
  return instance
    .get('/users/me')
    .then(({ data: { data } }) => {
      const userBusinessFromStorage = JSON.parse(localStorage.getItem('businessInfo'));
      data.business.forEach((item) => {
        item.roles = item.roles?.find((role) => role.userId === data._id)?.role;
      });
      const currentBusiness =
        data.business.find((item) => item._id === userBusinessFromStorage?.id) || data.business[0];
      if (!userBusinessFromStorage?.id || userBusinessFromStorage === 'null') {
        localStorage.setItem(
          'businessInfo',
          JSON.stringify({
            label: `${data.business[0].companyName} - ${
              data.business[0]?.address || data.user.business[0].addressInfo?.street1 || ''
            }`,
            id: data.business[0].id,
          })
        );
      }
      data.businessList = [...data.business];
      data.business = currentBusiness;
      dispatch({ type: SET_CURRENT_BUSINESSES, payload: currentBusiness });
      dispatch({ type: USER_LOADED, payload: { data } });
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({ type: USER_LOADING, payload: false });
    });
};

export const updateUserSession = () => (dispatch) => {
  return instance.put('/users/session').then(({ data: { data } }) => {
    dispatch(getUserData());
    /**
      * In here we need to set user data from response 
      @business - {array}
    */

    // const userBusinessFromStorage = localStorage.getItem('business');
    // data.business.forEach((item) => {
    //   item.roles = item.roles?.find((role) => role.userId === data._id)?.role;
    // });
    // const currentBusiness =
    //   data.business.find((item) => item._id === userBusinessFromStorage) || data.business[0] || [];
    // if (!userBusinessFromStorage) {
    //   localStorage.setItem('business', data.business[0]?._id);
    // }
    // data.businessList = [...data.business];
    // data.business = currentBusiness;
    // dispatch({ type: SET_CURRENT_BUSINESSES, payload: currentBusiness });
    // dispatch({ type: USER_LOADED, payload: { data } });
    // dispatch(getMenusV2());
  });
};

export const updateUserData = (body) => (dispatch, getState) => {
  const { user } = getState();
  return instance.put('/users/me', body).then(() => {
    dispatch({ type: USER_LOADED, payload: { data: { ...user.data, ...body }, loading: false } });
  });
};

export const updateUserPassword = (body) => {
  return instance.post('/users/change-password', body);
};

export const sendMessage = (body) => {
  return instance.post('/users/help', body);
};

export const updateUserMailbox = (body) => (dispatch) => {
  return instance.post('/users/update-mailbox', body).then(() => {
    dispatch(getUserData());
  });
};

export const verifyUser = (data) => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: true });
  return instance
    .post('/users/me/email/verify', data)
    .then((res) => res)
    .finally(() => {
      dispatch({ type: USER_LOADING, payload: false });
    });
};

export const confirmUser = (body) => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: true });
  return instance
    .post('/users/me/email/confirm', body)
    .then(({ data: { data } }) => {
      setInstance(data.token);
      return data;
    })
    .finally(() => {
      dispatch({ type: USER_LOADING, payload: false });
    });
};

export const confirmPassword = (body) => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: true });
  return instance
    .post('/users/change-password', body)
    .then(({ data: { data } }) => {
      setInstance(data.token);
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({
        type: USER_LOADING,
        payload: false,
      });
    });
};

export const updateUserBilling = (body) => {
  return instance.put(`/billing`, body);
};

export const deleteUserBilling = (id) => {
  return instance.delete(`/billing/card/${id}`);
};

export const getUserBilling = (body) => {
  return instance.get(`/billing`, body);
};

export const getPlans = () => {
  return instance.get(`/test/plan`);
};

export const getEmployers = () => {
  return instance.get('/users/employers').then(({ data: { data } }) => data);
};

export const deleteEmployer = (id) => {
  return instance.delete(`/auth/employers/${id}`);
};

// Disabled and Enabled Admin.

//  https://api01.demo.orders.co/disable/:id
export const disabledAdmin = (id) => (dispatch) => {
  return instance.put(`business/disable/${id}`, { id }).then((data) => {
    dispatch({
      type: SET_CURRENT_BUSINESSES_DISABLE,
      payload: data.data.data.business,
    });
  });
};

// https://api01.demo.orders.co/enable/:id
export const enabledAdmin = (id) => (dispatch) => {
  return instance.put(`business/enable/${id}`, { id }).then((data) => {
    dispatch({
      type: SET_CURRENT_BUSINESSES_ENABLE,
      payload: data.data.data.business,
    });
  });
};

export const downloadsMenuByProvider = (provider) => (dispatch) => {
  return instance.post(`menu/sync/${provider}`, {}).then(() => {
    dispatch(getMenusV2());
  });
};
