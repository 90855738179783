import instance from "../../services/api";
import { toast } from "react-toastify";
import { SET_AVAILABILITY_CUSTOM } from "redux/actions/types";

export const updateAvailabilitySettings = (body, id) => (dispatch) => {
  return instance
    .put(`/availability/${id}`, body)
    .then(({ data: { data } }) => {
      return data;
    })
    .catch((err) => {
      toast.error("Updated failed!");
      return Promise.reject(err);
    });
};
export const createAvailabilitySettings = (body, id) => (dispatch) => {
  return instance
    .post(`/availability`, body)
    .then(({ data: { data } }) => {
      return data;
    })
    .catch((err) => {
      // toast.error('Updated failed!');
      return Promise.reject(err);
    });
};
export const getAvailabilitySettings = () => {
  return instance.get("/availability").then(({ data: { data } }) => {
    return data;
  });
};
export const getAvailabilityById = (id) => {
  return instance.get(`/availability/${id}`).then(({ data: { data } }) => {
    return data;
  });
};
export const deleteAvailability = (id, body) => {
  return instance
    .delete(`/availability/${id}`, { data: body })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err && err.response;
    });
};
export const getAvailabilityCustom = () => (dispatch) => {
  return instance.get("/availability/custom").then(({ data: { data } }) => {
    // dispatch({ type: SET_AVAILABILITY_CUSTOM, payload: data });
    return data;
  });
};
