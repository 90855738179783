import React, { useEffect } from "react";
import { Button, Box, Typography, Divider, Stack, Grid } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useFieldArray, useWatch } from "react-hook-form";
import CustomTextInput from "components/Forms/CustomTextInput";
import { ImageUpload } from "components/FormFields";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import DragHandle from "components/DragHandle";
import PriceOverridePopover from "./PriceOverridePopover";
import { CONTEXT_TYPES } from "constants/menu";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const ProductForm = ({ data = {}, ...params }) => {
  const {
    readOnly,
    mode,
    changeIsOnePrice,
    handleAddModifierOption,
    handleDeleteModifierOption,
    sizeTypeModifierListFields,
    control,
    handleSortEnd,
    isInOverview,
    productFormMethods,
    handleResetPriceProvider,
    handleCleanPriceProvider,
    currentProductSizeTypeModifiers,
    handleClickOpen,
    currentCategory,
    menu,
    imageGetvalue,
    currentProduct,
    connectedProvidersByTarget,
    setDisable,
    onChangePrice,
  } = params;
  const {
    isOnePrice,
    connectedProviders,
    priceOverride,
    image,
    sizeTypeModifiers,
    category,
    _id,
  } = data;
  const basePrice = data.price || 0;
  const setImage = (value) => {
    productFormMethods.setValue("image", value);
    if (!value) {
      setDisable(true);
    }
    imageGetvalue(value || image);
  };
  const priceOverrideUnder = useWatch({ control, name: "priceOverride" });
  const isEdit = mode === "edit";

  return (
    <Box>
      <Grid container sx={{ px: 0 }}>
        <Grid
          sx={{
            mb: 2,
          }}
          item
          xs={12}
          xl={isInOverview ? 12 : 6}
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item xl={12} xs={12} md={12}>
            <CustomTextInput
              name="name"
              label="Name"
              sx={{ width: "100%", mt: 2 }}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ px: 0 }}>
        <Grid
          item
          xs={isInOverview ? 12 : 5}
          lg={5}
          xl={isInOverview ? 3 : 2}
          md={isInOverview ? 5 : 2}
        >
          <Box>
            <Typography>Photo</Typography>
            <ImageUpload
              setImage={setImage}
              initialValue={productFormMethods.getValues().image}
              name="image"
              className="mb-3"
              disabled={readOnly}
              removable
              setDisable={setDisable}
              productId={_id}
            />
            <CustomTextInput hidden={true} name="image" size="small" />
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ px: 0 }}>
        <Grid item xs={12} lg={12} xl={isInOverview ? 12 : 6} md={12}>
          <CustomTextInput
            name="description"
            label="Description"
            sx={{ width: "100%", mt: 3 }}
            multiline={true}
            rows={4}
            size="small"
          />
        </Grid>
      </Grid>

      {/* Price Section */}
      <Grid container>
        <Grid item xs={12}>
          <Box>
            {isOnePrice && (
              <>
                <Grid container alignItems="center" sx={{ mt: 2 }}>
                  <Grid item xs={"auto"}>
                    <Typography sx={{ color: "#3D4350", fontWeight: 500 }}>
                      Base Price
                    </Typography>
                  </Grid>
                  <Grid item sx={{ ml: 3 }}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 3 }}>
                  <Grid item xs={12}>
                    <CustomTextInput
                      InputProps={{
                        endAdornment: isOnePrice ? (
                          <PriceOverridePopover
                            handleReset={handleResetPriceProvider}
                            handleClean={handleCleanPriceProvider}
                            target={"productFormPricePopover"}
                            disabled={!isOnePrice || !category}
                            priceOverride={priceOverride}
                            connectedProviders={connectedProvidersByTarget}
                            onChangePrice={onChangePrice}
                            errors={productFormMethods?.formState?.errors}
                            isSubmitted={
                              productFormMethods?.formState?.isSubmitted
                            }
                          />
                        ) : null,
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        type: "number",
                        step: "any",
                        // onBlur: basePriceBlur,
                      }}
                      name={"price"}
                      label="Price"
                      size="small"
                      // step="any"
                      // initialValue={basePrice}
                      onChange={onChangePrice}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container sx={{ mt: 3 }}>
              <Grid item xs={12}>
                {isOnePrice && priceOverride ? (
                  <Box>
                    <Grid item xs={12}>
                      <Button
                        sx={{
                          color: "#333333",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                        onClick={() => changeIsOnePrice(false)}
                      >
                        <AddIcon sx={{ fontSize: "14px", mr: 1 }} /> Add Size
                      </Button>
                    </Grid>
                  </Box>
                ) : (
                  <Box>
                    <Grid container alignItems="center">
                      <Grid item xs={"auto"}>
                        <Typography sx={{ color: "#3D4350", fontWeight: 500 }}>
                          Price
                        </Typography>
                      </Grid>
                      <Grid item sx={{ ml: 3 }}>
                        <Divider />
                      </Grid>
                    </Grid>
                    <SortableList onSortEnd={handleSortEnd} useDragHandle>
                      {sizeTypeModifierListFields?.map((item, index) => {
                        return (
                          <SortableItem
                            control={control}
                            basePrice={basePrice}
                            menu={menu}
                            key={item.id}
                            item={item}
                            index={index}
                            onChangePrice={onChangePrice}
                            isInOverview={isInOverview}
                            priceOverride={priceOverride}
                            sizeTypeModifierListFields={
                              sizeTypeModifierListFields
                            }
                            connectedProviders={connectedProvidersByTarget}
                            itemIndex={index}
                            onDelete={() =>
                              handleDeleteModifierOption(index, item?._id)
                            }
                            onAdd={handleAddModifierOption}
                            sizeTypeModifiers={sizeTypeModifiers}
                            errors={productFormMethods?.formState?.errors}
                            currentCategory={currentCategory}
                            currentProductSizeTypeModifiers={
                              currentProductSizeTypeModifiers
                            }
                            mode={mode}
                            currentProduct={currentProduct}
                            isSubmitted={
                              productFormMethods?.formState?.isSubmitted
                            }
                          />
                        );
                      })}
                    </SortableList>
                  </Box>
                )}
              </Grid>
            </Grid>
            {data.modifierId && (
              <>
                <Grid container alignItems="center" sx={{ mt: 3 }}>
                  <Grid item xs={"auto"}>
                    <Typography sx={{ color: "#3D4350", fontWeight: 500 }}>
                      Modifier groups list
                    </Typography>
                  </Grid>
                  <Grid item xs sx={{ ml: 3 }}>
                    <Divider />
                  </Grid>
                </Grid>
                <Stack direction="row" sx={{ mt: 2 }}>
                  {data.parentModifierGroups?.map((modGroupName) => (
                    <Box
                      key={modGroupName._id}
                      variant="contained"
                      sx={{
                        background: "#CFDFFF",
                        border: "none",
                        color: "black",
                        mr: 1,
                        mt: 2,
                        padding: "8px 12px",
                        borderRadius: "3px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {modGroupName.name}
                    </Box>
                  ))}
                </Stack>
              </>
            )}
            <Box>
              <Grid container alignItems="center" sx={{ mt: 2 }}>
                <Grid item xs={"auto"}>
                  <Typography sx={{ color: "#3D4350", fontWeight: 500 }}>
                    Item tax
                  </Typography>
                </Grid>
                <Grid item sx={{ ml: 3 }}>
                  <Divider />
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", alignIgtems: "center", mt: 3 }}>
                <CustomTextInput
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  sx={{ width: "200px", mr: 2 }}
                  name="tax"
                  label="Tax"
                  size="small"
                  inputProps={{ type: "number" }}
                />
                <Typography component={"span"} sx={{ fontSize: "16px" }}>
                  *Based on your location, your store is responsible for
                  collecting and remitting all taxes. If you don’t specify a tax
                  rate, this item will be taxed using your store’s tax rate.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const SortableItem = SortableElement(
  ({
    item,
    onDelete,
    onAdd,
    itemIndex,
    connectedProviders,
    sizeTypeModifierListFields,
    control,
    priceOverride,
    isInOverview,
    currentProductSizeTypeModifiers = [],
    sizeTypeModifiers,
    currentCategory,
    menu,
    mode,
    basePrice,
    currentProduct,
    onChangePrice,
    errors,
  }) => {
    const target = sizeTypeModifierListFields[itemIndex];
    const sizeTypeModifierListPriceOverrideFields = useFieldArray({
      control,
      name: `sizeTypeModifiers[0].list[${itemIndex}].priceOverride`,
    });

    useEffect(() => {
      const providerPriceOverride = [];
      if (mode === "create" || !fields.length) {
        let targetConnectedProviders;
        if (currentCategory) {
          targetConnectedProviders = currentCategory.connectedProviders;
        } else {
          targetConnectedProviders = menu.connectedProviders;
        }
        Object.keys(targetConnectedProviders).map((modItem) => {
          if (targetConnectedProviders[modItem]) {
            providerPriceOverride.push({
              context_type: CONTEXT_TYPES.provider,
              context_value: modItem,
              name: modItem,
              price: 0,
            });
          }
        });
        sizeTypeModifierListPriceOverrideFields.replace(providerPriceOverride);
      }
    }, [mode, currentCategory]);

    const { fields, update } = sizeTypeModifierListPriceOverrideFields;

    useEffect(() => {
      if (mode !== "create") {
        if (!sizeTypeModifierListPriceOverrideFields.fields.length) {
          sizeTypeModifierListPriceOverrideFields.replace([...priceOverride]);
        }
      }
    }, [target, mode]);

    const handleReset = () => {
      fields.map((fieldItem, index) => {
        let price;
        if (item?.price || item?.price === 0) {
          price = item?.price;
        } else {
          price = fieldItem?.price;
        }
        update(index, { ...fieldItem, price });
      });
    };
    const handleClean = () => {
      fields.map((fieldItem, index) => {
        const target = currentProductSizeTypeModifiers[0]?.list[
          itemIndex
        ]?.priceOverride.find(
          (targetItem) => fieldItem.context_value === targetItem.context_value
        );
        let price;
        if (target?.price || target?.price === 0) {
          price = target?.price;
        } else {
          price = fieldItem?.price;
        }
        update(index, { ...fieldItem, price });
      });
    };
    return (
      <Box>
        <Stack direction="row" sx={{ mt: 2, position: "relative" }}>
          <DragHandle className="p-2 d-inline-block" />
          <CustomTextInput
            label={`Option ${itemIndex + 1}`}
            name={`sizeTypeModifiers[0].list[${itemIndex}].name`}
            sx={{ mr: 2 }}
          />
          <CustomTextInput
            defaultValue={itemIndex}
            inputProps={{ type: "number" }}
            name={`sizeTypeModifiers[0].list[${itemIndex}].optionSort`}
            hidden={true}
          />
          <Box id={itemIndex}>
            <CustomTextInput
              InputProps={{
                endAdornment: (
                  <PriceOverridePopover
                    target={"productFormSizeTypePricePopover"}
                    connectedProviders={connectedProviders}
                    itemIndex={itemIndex}
                    priceOverride={fields}
                    handleReset={handleReset}
                    handleClean={handleClean}
                    onChangePrice={onChangePrice}
                    errors={errors}
                  />
                ),
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              inputProps={{ type: "number", step: "any" }}
              name={`sizeTypeModifiers[0].list[${itemIndex}].price`}
              label="Price"
              itemIndex={itemIndex}
              size="small"
              onChange={onChangePrice}
              // step="any"
            />
          </Box>
          {itemIndex === 0 ? (
            <Button
              sx={{
                height: "40px",
                ml: 2,
                background: "#F2F2F2",
                color: "#323232",
              }}
              onClick={onAdd}
            >
              <AddIcon />
            </Button>
          ) : (
            <Button
              sx={{
                height: "40px",
                ml: 2,
                background: "#F2F2F2",
                color: "#323232",
              }}
              onClick={onDelete}
            >
              <DeleteOutlineIcon />
            </Button>
          )}
        </Stack>
      </Box>
    );
  }
);

const SortableList = SortableContainer(({ children }) => <Box>{children}</Box>);

export default ProductForm;
