import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import store from "./store";
import { ClearCacheProvider } from "react-clear-cache";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SocketProvider } from "providers/Socket";
import theme from "./Theme/index";
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.scss";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51JfinxFayKrX6yHIb62cJdHYIfZMRNEJBnYm8o7nmhK7ugQzpgzHWsLXuyK99f4dGqIYPr0H9LflVrQxGOpcUd4V00vCER6fVf"
);
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ClearCacheProvider duration={5000}>
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <SocketProvider>
            <Router>
              <Switch>
                <Route path="/" component={App} />
              </Switch>
            </Router>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              pauseOnHover
            />
          </SocketProvider>
        </Provider>
      </Elements>
    </ClearCacheProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
