import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useClearCacheCtx } from "react-clear-cache";
import { useLocation } from "react-router-dom";
import { ocoPost, ocoPatch, osoPatchUrl } from "helpers/googleTagManager";
import Main from "pages/Main";
import GoogleTagWidget from "components/GoogleTagWidget";
import axios from "axios";

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  const location = useLocation();

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, []);

  return (
    <GoogleTagWidget>
      <Main />
    </GoogleTagWidget>
  );
}

export default App;
