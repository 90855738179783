import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(({ className }) => (
  <span className={`drag-handle ${className}`}>
    <i className="icon icon-align-justify" />
  </span>
));

export default DragHandle;
