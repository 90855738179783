import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { styled } from "@mui/material/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LinkedProviderPopover from "../LinkedProviderPopover";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import Private from "components/Private";
import InfoBulkIcon from "assets/icons/info_icon_bulk.svg";
import moment from "moment-timezone";
import { renderPermanently } from "utils/soldOut";

const StyledBreadcrumbs = styled(Breadcrumbs)`
  .MuiBreadcrumbs-ol {
    flex-wrap: nowrap;
  }
`;
const useStyle = makeStyles((theme) => ({
  root: {
    maxWidth: (props) => `${props.width}px`,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",

    ol: {
      flexWrap: "nowrap",
      width: "100%",
    },
  },
}));

const FormHeader = ({
  title,
  currentProduct,
  cid,
  entityType,
  currentModifierName,
  setCurrentModifierName,
  // currentModifier,
  mode,
  handleDelete,
  category,
  menu,
  connectedProviders,
  data,
  formProduct,
}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const productTitleRef = useRef();
  const headRef = useRef();
  const trimTrim = title?.trim();
  const categoryId = menu.categories?.find((el) => el._id === cid);
  const isEdit = mode === "edit";
  const classes = useStyle();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToProductView = () => {
    setCurrentModifierName("");
    history.push({
      search: `?entityType=item&pid=${currentProduct?._id}&cid=${cid}&mode=${mode}`,
    });
  };

  const isInOverview = history?.location.pathname?.includes("/overview");
  const goBack = () => {
    history.push("/menu/items");
  };

  const onDelete = () => {
    setAnchorEl(null);
    handleDelete();
  };

  const onDuplicate = () => {};
  const onItemChanges = () => {};

  return (
    <Box
      sx={{
        px: 4,
        py: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: 1,
        borderColor: "divider",
      }}
      className="header-menu"
    >
      {entityType === "modifier" ? (
        <StyledBreadcrumbs
          sx={{
            width: "100%",
            fontSize: "1.25rem",
            fontWeight: 600,
            color: "text.primary",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          ref={headRef}
          className={classes.root.ol}
          aria-label="breadcrumb"
        >
          <IconButton onClick={goToProductView}>
            <ArrowBackIcon />
          </IconButton>
          <Tooltip title={currentProduct?.name} placement="top">
            <Typography
              ref={productTitleRef}
              className={classes.root}
              nowrap="true"
              sx={{ color: "#9E9EAD", fontSize: "1.25rem" }}
              color="inherit"
            >
              {currentProduct?.name}
            </Typography>
          </Tooltip>

          {currentModifierName ? (
            <Tooltip title={currentModifierName} placement="top">
              <Typography
                className={classes.root}
                nowrap="true"
                sx={{ fontSize: "1.25rem", fontWeight: 600 }}
                color="text.primary"
              >
                {currentModifierName}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              nowrap="true"
              sx={{ fontSize: "1.25rem", fontWeight: 600 }}
              color="text.primary"
            >
              {"Add a new modifier group"}
            </Typography>
          )}
        </StyledBreadcrumbs>
      ) : isInOverview ? (
        <>
          <StyledBreadcrumbs
            aria-label="breadcrumb"
            sx={{
              width: "100%",
              fontSize: "1.25rem",
              fontWeight: 600,
              color: "text.primary",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <IconButton onClick={() => history.goBack()}>
              <ArrowBackIcon />
            </IconButton>
            <Tooltip title={category?.name} placement="top">
              <Typography
                className={classes.root}
                nowrap="true"
                sx={{ color: "#9E9EAD", fontSize: "1.25rem" }}
                color="inherit"
              >
                {category?.name}
              </Typography>
            </Tooltip>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip title={trimTrim} placement="top">
                <Typography className={classes.root} nowrap="true" variant="h6">
                  {!isInOverview && (
                    <KeyboardBackspaceIcon
                      sx={{ mr: 2, cursor: "pointer" }}
                      onClick={goBack}
                    />
                  )}
                  {trimTrim || "Add a new item"}
                </Typography>
              </Tooltip>
              {isEdit && (
                <LinkedProviderPopover
                  id={currentProduct?._id}
                  connectedProviders={connectedProviders}
                  target="item"
                  targetConnectedProviders={category?.connectedProviders}
                />
              )}
            </Box>
          </StyledBreadcrumbs>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 1.5,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#EF3061",
                fontWeight: 500,
                justifyContent: "center",
                display: "flex",
              }}
              variant="body1"
            >
              {renderPermanently(formProduct || data, moment) && isEdit ? (
                <>
                  <Box
                    component="img"
                    sx={{ mr: 0.5 }}
                    src={InfoBulkIcon}
                    alt="INFO_BULK_ICON"
                  />
                  {renderPermanently(formProduct || data, moment)}
                </>
              ) : (
                ""
              )}
            </Typography>
          </Box>
        </>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title={trimTrim} placement="top">
              <Typography nowrap="true" variant="h6">
                {!isInOverview && (
                  <KeyboardBackspaceIcon
                    sx={{ mr: 2, cursor: "pointer" }}
                    onClick={goBack}
                  />
                )}
                {trimTrim || "Add a new item"}
              </Typography>
            </Tooltip>
            <Private permission="MENU_EDIT">
              {isEdit && !currentProduct?.modifierId && (
                <LinkedProviderPopover
                  id={currentProduct?._id}
                  categoryId={categoryId?._id}
                  connectedProviders={connectedProviders}
                  target="item"
                  targetConnectedProviders={
                    categoryId
                      ? categoryId?.connectedProviders
                      : menu.connectedProviders
                  }
                />
              )}
            </Private>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 0.5,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#EF3061",
                fontWeight: 500,
                justifyContent: "center",
                display: "flex",
              }}
              variant="body1"
            >
              {renderPermanently(formProduct || data, moment) && isEdit ? (
                <>
                  <Box
                    component="img"
                    sx={{ mr: 0.5 }}
                    src={InfoBulkIcon}
                    alt="INFO_BULK_ICON"
                  />
                  {renderPermanently(formProduct || data, moment)}
                </>
              ) : (
                ""
              )}
            </Typography>
          </Box>
        </Box>
      )}
      <Private permission="MENU_EDIT">
        {entityType !== "modifier" ? (
          <>
            <IconButton sx={{ ml: 1 }} disableRipple onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem disabled={!isEdit} onClick={onDelete}>
                Delete
              </MenuItem>
              <MenuItem disabled onClick={onDuplicate}>
                Duplicate
              </MenuItem>
              <MenuItem disabled onClick={onItemChanges}>
                See item changes
              </MenuItem>
            </Menu>
          </>
        ) : (
          ""
        )}
      </Private>
    </Box>
  );
};

export default FormHeader;
