export const priceTransform = (value) => {
  if (isNaN(value)) {
    return null;
  } else {
    const clean = value.replace(/\D/g, '');
    const result = String(+clean)
      .padStart(3, '0')
      .replace(/(..)$/, '.$1');
    return result;
  }
};
