import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import Private from 'components/Private';
const FormFooter = ({ isDisable, goToModifierOptions, isInOverview, mode }) => {
  const history = useHistory();
  const disabled = mode === 'edit' && !isInOverview ? !isDisable : false;
  return (
    <Box
      sx={{
        py: 3,
        px: 4,
        borderTop: 1,
        borderColor: 'divider',
      }}
    >
      <Private permission="MENU_EDIT">
        <Button disabled={disabled} type="submit" variant="contained" sx={{ mr: 1 }}>
          {isInOverview ? 'Save & Continue' : mode === 'edit' ? 'Update' : 'Save'}
        </Button>
      </Private>
      <Button sx={{ color: '#333333' }} onClick={() => history.goBack()}>
        Cancel
      </Button>
    </Box>
  );
};

export default FormFooter;
