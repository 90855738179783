import React from 'react';
import { Grid } from '@mui/material';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { classificationOptions } from 'constants/classifications';
import MultiSelectChips from 'components/Forms/MultiSelectChips';
import CustomCheckbox from 'components/Forms/CustomCheckbox';

const ProductForm = ({ data }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <CustomTextInput
          inputProps={{ type: 'number' }}
          min="0"
          name="proteins"
          label="Proteins"
          size="small"
          sx={{ width: '100%', mt: 1 }}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomTextInput
          inputProps={{ type: 'number' }}
          min="0"
          name="carbs"
          label="Carbs"
          size="small"
          sx={{ width: '100%', mt: 1 }}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomTextInput
          inputProps={{ type: 'number' }}
          min="0"
          name="calories"
          label="Calories"
          size="small"
          sx={{ width: '100%', mt: 1 }}
        />
      </Grid>
      <Grid item xs={12}>
        <MultiSelectChips
          sx={{ width: '100%', mt: 2 }}
          name="tags"
          label="Classification"
          options={classificationOptions}
          multiple
          defaultValues={data.tags || []}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextInput
          inputProps={{ type: 'text' }}
          name="kitchenInstruction"
          label="Kitchen instruction"
          size="small"
          multiline={true}
          rows={3}
          sx={{ width: '100%', mt: 1 }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextInput
          inputProps={{ type: 'text' }}
          name="recipe"
          label="Recipe"
          size="small"
          multiline={true}
          rows={3}
          sx={{ width: '100%', mt: 1 }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomCheckbox
          checked={!data.onItsOwn}
          disabled={true}
          sx={{ mt: 2 }}
          label="Only Used as Modifier Option"
          name="onItsOwn"
        />
      </Grid>
    </Grid>
  );
};

export default ProductForm;
