import { findIndex } from 'lodash';
import { largeIcons } from 'services/serviceIcons';
import { toast } from 'react-toastify';
import instance from 'services/api';
import {
  SET_CATEGORY_PRODUCTS_V2,
  SET_LOADING,
  SET_MENU_CATEGORIES_V2,
  SET_PAGINATION_V2,
  SET_MENUS_V2,
  SET_MENU_PRODUCTS_ALL,
  SET_MENU_MODIFIERS_ALL,
  SET_LINK_PROVIDER_LOADING,
  SET_LINK_PROVIDER_VISIBILITY,
  SET_FORM_PRODUCT,
} from '../types';

export const getCategoryProductsV2 = (id) => (dispatch, getState) => {
  // const { menu } = getState();
  const { menu, paginationConfig, params, formProduct } = getState().menu;
  const categories = [...menu.categories];
  const { skip, limit } = paginationConfig;
  return instance
    .get(`/categories/v2/${id}?sortDirection=ASC&products=true`)
    .then(({ data: { data } }) => {
      setTimeout(() => {
        return data.products.forEach((item) => {
          if (item._id === formProduct._id) {
            dispatch(updateProductForm({ ...formProduct, ...item }));
          }

          if (!item.hasOwnProperty('isOnePrice')) {
            item.isOnePrice = true;
          }
        });
      }, [0]);

      const targetCategoryIndex = categories.findIndex((cat) => cat._id === id);
      if (targetCategoryIndex != -1) {
        categories[targetCategoryIndex].products = data.products;
      }

      dispatch({
        type: SET_PAGINATION_V2,
        payload: {
          count: Math.ceil(data.count / limit),
        },
      });
      dispatch({
        type: SET_CATEGORY_PRODUCTS_V2,
        payload: categories,
      });
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const reorderProducts = (data, categoryId) => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();
  const { categories } = menu;
  let targetCategoryIndex = categories.findIndex((item) => item._id === categoryId);
  if (targetCategoryIndex != -1) {
    let mySortedArray = data.map((x) => categories[targetCategoryIndex].products.find((y) => y._id === x));
    categories[targetCategoryIndex].products = mySortedArray;
    dispatch({
      type: SET_CATEGORY_PRODUCTS_V2,
      payload: categories,
    });
    return instance.put(
      `products/sort`,
      data.map((_id, sortIndex) => ({ _id, sortIndex }))
    );
  }
};

export const getProductById = (productId, categoryId) => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();
  const { categories } = menu;
  return instance
    .get(`products/${productId}`)
    .then((res) => {
      if (!res.data.data.tax && res.data.data.tax !== 0) {
        res.data.data.tax = '';
      }
      if (!res.data.data.isOnePrice) {
        if (res.data.data.sizeTypeModifiers[0]) {
          res.data.data.sizeTypeModifiers[0]['deletedOptionIds'] = [];
        }
      }
      dispatch(updateProductForm(res.data.data));
      return res.data.data;
    })

    .catch((err) => {
      return Promise.reject(err);
    });
};

export const patchProductSoldOutById = (productId, body) => (dispatch) => {
  return instance
    .patch(`products/sold-out/${productId}`, {
      status: body.status,
    })
    .then((res) => {
      dispatch(updateProductForm(res.data.data));
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getProductsAll = () => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  const skip = 0;
  const limit = 1000;
  return instance
    .get(`/products/all?skip=${skip}&sortDirection=ASC&products=true`)
    .then((res) => {
      dispatch({ type: SET_MENU_PRODUCTS_ALL, payload: res.data.data });
    })
    .catch((err) => {
      toast.error(err.res);
    })
    .finally(() => dispatch({ type: SET_LOADING, payload: false }));
};

export const setProductsFromSocket = (data) => (dispatch, getState) => {
  const {
    menu: {
      menu: { categories, productsAll },
    },
  } = getState();
  dispatch(updateProductForm(data));
  const copyOfProductsAll = [...productsAll];
  let index = findIndex(copyOfProductsAll, { id: data._id });
  copyOfProductsAll[index] = {
    ...copyOfProductsAll[index],
    ...data,
  };
  dispatch({ type: SET_MENU_PRODUCTS_ALL, payload: copyOfProductsAll });

  const categoryIdFromProduct = data?.category?._id;
  const targetCategoryIndex = findIndex(categories, { _id: categoryIdFromProduct });

  // const targetProductIndex = categories[targetCategoryIndex]?.products.findIndex((product) => product._id === data._id);
  const targetProductIndex = findIndex(categories[targetCategoryIndex]?.products, { _id: data._id });
  if (targetCategoryIndex !== -1 && targetProductIndex !== -1) {
    categories[targetCategoryIndex].products[targetProductIndex] = data;
    dispatch({
      type: SET_CATEGORY_PRODUCTS_V2,
      payload: categories,
    });
  }
};

export const updateProductSocket = (data) => (dispatch, getState) => {
  dispatch(updateProductForm(data, true));
};

export const createProductSocket = (data) => (dispatch, getState) => {
  const {
    menu: {
      menu: { categories },
    },
  } = getState();
  if (!data?.category?._id) {
    return;
  }
  const copyCat = [...categories];
  const categoryIdFromProduct = data?.category?._id || data?.category;
  const targetCategoryIndex = findIndex(copyCat, { _id: categoryIdFromProduct });
  if (copyCat[targetCategoryIndex]) {
    copyCat[targetCategoryIndex].productsCount += 1;
  }
  dispatch({ type: SET_MENU_CATEGORIES_V2, payload: copyCat });
  dispatch(getCategoryProductsV2(data?.category?._id));
  // dispatch(updateProductForm(data));
  // dispatch(getProductsAll());
};

export const deleteProductSocket = (data) => (dispatch, getState) => {
  const {
    menu: {
      menu: { categories },
    },
  } = getState();
  if (!data?.categoryId) {
    return;
  }
  const copyCat = [...categories];
  const categoryIdFromProduct = data?.category?._id || data?.category;
  const targetCategoryIndex = findIndex(copyCat, { _id: categoryIdFromProduct });
  if (copyCat[targetCategoryIndex]) {
    copyCat[targetCategoryIndex].productsCount -= 1;
  }
  dispatch({ type: SET_MENU_CATEGORIES_V2, payload: copyCat });
  dispatch(getCategoryProductsV2(data?.categoryId));
  // dispatch(getProductsAll());
};

export const updateProductForm = (data, fromSocket = false) => (dispatch, getState) => {
  const {
    menu: {
      menu: { categories },
      formProduct,
    },
  } = getState();
  if (categories) {
    const copyCategory = [...categories];
    const targetCategoryIndex = copyCategory.findIndex(
      (cat) => cat._id === data.category?._id || cat._id === data.category
    );

    if (targetCategoryIndex != -1) {
      if (copyCategory[targetCategoryIndex].products) {
        copyCategory[targetCategoryIndex].products.forEach((item, index) => {
          if (item._id === data._id) {
            categories[targetCategoryIndex].products[index] = data;
          }
        });
        dispatch({
          type: SET_CATEGORY_PRODUCTS_V2,
          payload: categories,
        });
      }
    }
  }
  if (!fromSocket || data._id === formProduct._id) {
    dispatch({ type: SET_FORM_PRODUCT, payload: data });
  }
};
