const prefix = "V2";

// Menus
export const GET_MENUS_V2 = `GET_MENUS_${prefix}`;
export const SET_MENUS_V2 = `SET_MENUS_${prefix}`;

// Link with provider
export const SET_LINK_PROVIDER_VISIBILITY = `SET_LINK_PROVIDER_VISIBILITY`;
export const SET_LINK_PROVIDER = `SET_LINK_PROVIDER`;
export const SET_CONNECTED_PROVIDERS = `SET_CONNECTED_PROVIDERS`;
export const SET_TARGET_CONNECTED_PROVIDERS = `SET_TARGET_CONNECTED_PROVIDERS`;
export const RESET_LINK_PROVIDER = `RESET_LINK_PROVIDER`;
export const SET_LINK_PROVIDER_LOADING = `SET_LINK_PROVIDER_LOADING`;
export const PROVIDERS_START_RESUME = `PROVIDERS_START_RESUME`;
export const SET_PROVIDER_DOORDASH_LOADING = `SET_PROVIDER_DOORDASH_LOADING`;
export const SET_PROVIDER_GRUBHUB_LOADING = `SET_PROVIDER_GRUBHUB_LOADING`;

// Menu categories
export const GET_MENU_CATEGORIES_V2 = `GET_MENU_CATEGORIES_${prefix}`;
export const SET_MENU_CATEGORIES_V2 = `SET_MENU_CATEGORIES_${prefix}`;

// Menu Modifiers
export const SET_MENU_MODIFIERS_ALL = `SET_MENU_MODIFIERS_ALL_${prefix}`;
export const SET_MENU_MODIFIERS_LOADING = `SET_MENU_MODIFIERS_LOADING_${prefix}`;
export const SET_CURRENT_MODIFIER = `SET_CURRENT_MODIFIER_${prefix}`;

// Menu category products
export const SET_MENU_PRODUCTS_ALL = `SET_MENU_PRODUCTS_ALL_${prefix}`;
export const GET_CATEGORY_PRODUCTS_V2 = `GET_CATEGORY_PRODUCTS_${prefix}`;
export const SET_CATEGORY_PRODUCTS_V2 = `SET_CATEGORY_PRODUCTS_${prefix}`;
export const SET_FORM_PRODUCT = `SET_FORM_PRODUCT_${prefix}`;

// Orders
export const GET_ORDERS = `GET_ORDERS`;
export const SET_ORDERS = `SET_ORDERS`;
export const ORDERS_PUSH = `ORDERS_PUSH`;
export const ORDERS_EMPTY = `ORDERS_EMPTY`;
export const SET_ORDERS_PAGINATION = "SET_ORDERS_PAGINATION";
export const SET_ORDERS_PARAMS = "SET_ORDERS_PARAMS";
export const RESET_ORDERS_PAGINATION = "RESET_ORDERS_PAGINATION";
export const UPDATE_MODIFIED_ORDERS = "UPDATE_MODIFIED_ORDERS";
export const RESET_MODIFIED_ORDERS = "RESET_MODIFIED_ORDERS";
export const SET_LAST_UPDATED_ORDER = "SET_LAST_UPDATED_ORDER";

export const SET_LAST_CANCELED_ORDER = "SET_LAST_CANCELED_ORDER";
export const UPDATE_CANCELED_ORDERS = "UPDATE_CANCELED_ORDERS";
export const RESET_CANCELED_ORDERS = "RESET_CANCELED_ORDERS";
export const SET_ORDERS_REPORT = "SET_ORDERS_REPORT";

// Loading
export const SET_LOADING = "SET_LOADING";

// Pagination
export const SET_PAGINATION_V2 = `SET_PAGINATION_${prefix}`;

// MenuV2 params
export const SET_MENU_PARAMS_V2 = `SET_MENU_PARAMS_${prefix}`;

// Menu Socket categories
export const SET_MENU_CATEGORIES_V2_SOCKET_CREATE = `SET_MENU_CATEGORIES_${prefix}_SOCKET_CREATE`;
export const SET_MENU_CATEGORIES_V2_SOCKET_UPDATE = `SET_MENU_CATEGORIES_${prefix}_SOCKET_UPDATE`;
export const SET_MENU_CATEGORIES_V2_SOCKET_DELETE = `SET_MENU_CATEGORIES_${prefix}_SOCKET_DELETE`;
export const SET_MENU_CATEGORIES_V2_SOCKET_PUSH = `SET_MENU_CATEGORIES_${prefix}_SOCKET_PUSH`;
export const SET_MENU_CATEGORIES_V2_SOCKET_SORT = `SET_MENU_CATEGORIES_${prefix}_SOCKET_SORT`;
export const SET_MENU_CATEGORIES_V2_SOCKET_IMPORT = `SET_MENU_CATEGORIES_${prefix}_SOCKET_IMPORT`;

// Settings Events
export const ADD_USER = `ADD_USER`;
export const SET_EVENT_DEFAULT = `SET_EVENT_DEFAULT`;
export const SET_EVENT_ACTIONS = `SET_EVENT_ACTIONS`;
export const SET_EVENT_LIST = `SET_EVENT_LIST`;
export const UPDATE_EVENT_LIST = `UPDATE_EVENT_LIST`;
export const ADD_EVENT_LIST = `ADD_EVENT_LIST`;
export const DELETE_EVENT_LIST = `DELETE_EVENT_LIST`;
export const SET_EVENT_LIST_LOADING = `SET_EVENT_LIST_LOADING`;

//Settings Printers
export const GET_PRINTERS_LIST = `GET_PRINTERS_LIST`;
export const SET_PRINTERS_LIST = `SET_PRINTERS_LIST`;
export const UPDATE_PRINTERS_LIST = `UPDATE_PRINTERS_LIST`;
export const ADD_PRINTERS_LIST = `ADD_PRINTERS_LIST`;
export const DELETE_PRINTERS_LIST = `DELETE_PRINTERS_LIST`;
export const SET_PRINTERS_LIST_LOADING = `SET_PRINTERS_LIST_LOADING`;

//Multiple Businesses
export const SET_CURRENT_BUSINESSES = `SET_CURRENT_BUSINESSES`;
export const SET_CURRENT_BUSINESSES_DISABLE = `SET_CURRENT_BUSINESSES_DISABLE`;
export const SET_CURRENT_BUSINESSES_ENABLE = `SET_CURRENT_BUSINESSES_ENABLE`;
export const SET_TIMEZONE = `SET_TIMEZONE`;

// Reports
export const SALES_BY_LIST = `SALES_BY_LIST`;
export const REPORT_LIST = `REPORT_LIST`;
export const SET_REPORT_LIST = `SET_REPORT_LIST`;
export const SET_REPORT_LOADING = `SET_REPORT_LOADING`;
export const SET_REPORT_FILTERS = `SET_REPORT_FILTERS`;
export const GET_REPORT_FILTERS = `GET_REPORT_FILTERS`;
// export const ADD_REPORT_FILTERS = `ADD_REPORT_FILTERS`;
export const DELETE_REPORT_FILTERS = `DELETE_REPORT_FILTERS`;

// Availability
export const SET_AVAILABILITY_CUSTOM = `SET_AVAILABILITY_CUSTOM`;

// Upload Bulk
export const SET_CHECKED_PROVIDER = "SET_CHECKED_PROVIDER";
