import React, { useState } from 'react';
import { IconButton, TextField, Box, FormControl, InputAdornment } from '@mui/material/';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
  },
  OutlinedTextField: {
    '& .MuiOutlinedInput-input': {
      padding: '5px 14px',
      fontSize: '14px',
    },
  },
}));

const SearchBarMenu = ({ sx, placeholder, variant, setSearchQuery }) => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <TextField
        className={classes.OutlinedTextField}
        sx={sx}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                sx={{
                  color: '#323232',
                  fontSize: '20px',
                }}
              />
            </InputAdornment>
          ),
          classes: {
            notchedOutline: classes.noBorder,
          },
        }}
        onInput={(e) => {
          setSearchQuery(e.target.value);
        }}
        variant={variant}
        placeholder={placeholder}
        size="small"
      />
    </Box>
  );
};

export default SearchBarMenu;
