import { PROVIDERS } from 'constants/menu';
import instance, { gatewayInstance } from '../../../services/api';
import {
  SET_CATEGORY_PRODUCTS_V2,
  SET_LOADING,
  SET_PAGINATION_V2,
  SET_MENUS_V2,
  SET_LINK_PROVIDER_LOADING,
  SET_LINK_PROVIDER_VISIBILITY,
  SET_CHECKED_PROVIDER,
  SET_PROVIDER_DOORDASH_LOADING,
  SET_PROVIDER_GRUBHUB_LOADING,
  SET_LINK_PROVIDER,
} from '../types';
import { updateCategory } from './categories';
import { updateProductForm } from './products';

const mocker = [
  {
    availability: 'available',
    _id: '63d36c81c7d082bbdc0b3810',
    name: 'Gourmet Burgers',
    description: '1/4lb.',
    externalId: '17426714',
    externalMenuId: '1478425',
    menuId: '5fa2ea8d814815dfe71b2730',
    externalParentId: '1478425',
    restaurantMailbox: 'lilitcompany@restaurantmailbox.com',
    __v: 0,
    id: '63d36c81c7d082bbdc0b3810',
    mappedData: [
      {
        _id: '63d3a480e1feac41c5067609',
        externalId: '17426737',
        restaurantMailbox: 'lilitcompany@restaurantmailbox.com',
        nativeId: '17426737',
        type: 'category',
        externalMenuId: '1478425',
      },
    ],
  },
  {
    availability: 'available',
    _id: '63d36c81c7d082bbdc0b3811',
    name: 'Salads',
    description: '1/4lb.',
    externalId: '17426714',
    externalMenuId: '1478425',
    menuId: '5fa2ea8d814815dfe71b2730',
    externalParentId: '1478425',
    restaurantMailbox: 'lilitcompany@restaurantmailbox.com',
    __v: 0,
    id: '63d36c81c7d082bbdc0b3810',
    mappedData: [
      {
        _id: '63d3a480e1feac41c5067609',
        externalId: '17426737',
        restaurantMailbox: 'lilitcompany@restaurantmailbox.com',
        nativeId: '17426737',
        type: 'category',
        externalMenuId: '1478425',
      },
    ],
  },
  {
    availability: 'available',
    _id: '63d36c81c7d082bbdc0b3812',
    name: 'Salads 3',
    description: '1/4lb.',
    externalId: '17426714',
    externalMenuId: '1478425',
    menuId: '5fa2ea8d814815dfe71b2730',
    externalParentId: '1478425',
    restaurantMailbox: 'lilitcompany@restaurantmailbox.com',
    __v: 0,
    id: '63d36c81c7d082bbdc0b3810',
    mappedData: [
      {
        _id: '63d3a480e1feac41c5067609',
        externalId: '17426737',
        restaurantMailbox: 'lilitcompany@restaurantmailbox.com',
        nativeId: '17426737',
        type: 'category',
        externalMenuId: '1478425',
      },
    ],
  },
];

export const getMenusV2 = () => (dispatch, getState) => {
  const {
    menu: { menus },
  } = getState();
  dispatch({ type: SET_LOADING, payload: true });
  return instance
    .get('/menu/v2')
    .then(({ data: { data } }) => {
      dispatch({ type: SET_MENUS_V2, payload: data });
      const defaultCheckedProviders = Object.keys(data.connectedProviders)
        .map((item) => {
          if (item === 'website' || item === 'main') {
            return;
          }
          return { [item]: data.connectedProviders[item] };
        })
        ?.filter((item) => item);
      localStorage.setItem(SET_CHECKED_PROVIDER, JSON.stringify(defaultCheckedProviders));
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally(() => dispatch({ type: SET_LOADING, payload: false }));
};

export const getCategoryProductsV2ByCategoryId = ({ selectedCategory, selectedMenu }) => (dispatch, getState) => {
  const { menu } = getState();
  const skip = 0;
  const limit = 1000;
  const { menus } = menu;
  const categoryData = {
    ...menus,
  };
  if (!selectedCategory || !selectedMenu || !categoryData) {
    return;
  }
  if (!categoryData?.products?.length) {
    dispatch({ type: SET_LOADING, payload: true });
  }
  return instance
    .get(`/categories/v2/${selectedCategory}?skip=${skip}&limit=${limit}&sortDirection=ASC&products=true`)
    .then(({ data: { data } }) => {
      categoryData[selectedMenu].categories[selectedCategory] &&
        (categoryData[selectedMenu].categories[selectedCategory].products = data?.products);
      dispatch({
        type: SET_PAGINATION_V2,
        payload: {
          count: Math.ceil(data.count / limit),
        },
      });
      dispatch({
        type: SET_CATEGORY_PRODUCTS_V2,
        payload: { baseData: categoryData },
      });
      return data;
    })
    .finally(() => dispatch({ type: SET_LOADING, payload: false }))
    .catch((err) => {
      return Promise.reject(err);
    });
};

// Link menu with provider

export const menuLinkProvider = (body) => (dispatch) => {
  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });
  return instance
    .post(`/menu/push`, body)
    .then(({ data: { data } }) => {
      dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: false });
    })
    .finally(() => dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false }));
};

export const categoryLinkProvider = (body) => (dispatch) => {
  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });
  return instance
    .post(`/menu/category/push`, body)
    .then(({ data: { data } }) => {
      dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: false });
    })
    .finally(() => dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false }));
};

export const productLinkProvider = (body) => (dispatch) => {
  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });
  return instance
    .post(`/menu/product/push`, body)
    .then(({ data: { data } }) => {
      dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: false });
    })
    .finally(() => dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false }));
};
export const linkProviderTarget = (sendDataProvider = []) => (dispatch, getState) => {
  const {
    menu: { linkProvider },
  } = getState();
  const { target, id, connectedProviders } = linkProvider;
  const providers = [];
  Object.keys(connectedProviders)
    .filter((provider) => provider !== 'main')
    .map((item) => {
      providers.push({
        provider: item,
        isLinked: connectedProviders[item],
      });
    });

  if (sendDataProvider.length && target === 'menu') {
    sendDataProvider.map((item) => {
      if (item.provider !== 'website') {
        dispatch(uploadBulk(item));
      }
    });
  }
  if (target === 'menu') {
    return dispatch(menuLinkProvider({ providers })).then(() => {
      window.location.href = '/menu/overview';
    });
  } else if (target === 'category') {
    return dispatch(categoryLinkProvider({ id, providers }));
  } else {
    return dispatch(productLinkProvider({ id, providers }));
  }
};

export const setMenuFromSocket = (data) => (dispatch, getState) => {
  dispatch({ type: SET_MENUS_V2, payload: data });
};

export const updateBulk = () => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();

  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });
  return instance
    .post(`menu/bulk`)
    .then(() => {
      menu.bulkInfo.isSaved = true;
      if (menu.modifiersAll) {
        menu.modifiersAll.forEach((modItem) => {
          modItem.bulkInfo.isSaved = true;
        });
      }

      menu.categories.forEach((catItem) => {
        catItem.bulkInfo.isSaved = true;
        catItem.products?.length &&
          catItem.products.forEach((productItem) => {
            productItem.bulkInfo.isSaved = true;
          });
      });
      menu.modifiersAll.forEach((modItem) => {
        modItem.bulkInfo.isSaved = true;
      });
      dispatch({ type: SET_MENUS_V2, payload: menu });
    })
    .finally(() => {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false });
    });
};

export const uploadBulk = (body) => (dispatch) => {
  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });
  return instance.post(`menu/bulk/upload`, body).finally(() => {
    dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false });
  });
};

export const doordashMapProvider = (body) => (dispatch) => {
  dispatch({ type: SET_PROVIDER_DOORDASH_LOADING, payload: true });
  return instance
    .post(`/menu/bulk/map`, body)
    .then((response) => {
      dispatch({ type: SET_PROVIDER_DOORDASH_LOADING, payload: false });
    })
    .finally(() => {
      dispatch({ type: SET_PROVIDER_DOORDASH_LOADING, payload: false });
    });
};

export const grubHubMapProvider = (body) => (dispatch) => {
  dispatch({ type: SET_PROVIDER_GRUBHUB_LOADING, payload: true });
  return instance
    .post(`/menu/bulk/map`, body)
    .then((response) => {
      dispatch({ type: SET_PROVIDER_GRUBHUB_LOADING, payload: false });
    })
    .finally(() => {
      dispatch({ type: SET_PROVIDER_GRUBHUB_LOADING, payload: false });
    });
};

export const getDoordashMenuTargetData = () => (dispatch, getState) => {
  const {
    business: { currentBusiness },
    menu: { linkProvider },
  } = getState();
  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });

  const type = linkProvider.target === 'item' ? 'product' : linkProvider.target;
  return gatewayInstance
    .get(`doordash-menu/${type}/${currentBusiness.restaurantMailbox}`)
    .then((res) => {
      dispatch({
        type: SET_LINK_PROVIDER,
        payload: {
          doordashTargetList: res.data.data,
        },
      });
      // dispatch(updateCategory(res.data.data._id));
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false });
    });
};

export const mapTargetToDoordash = (body) => (dispatch, getState) => {
  const {
    menu: {
      linkProvider: { targetData, target },
      menu,
    },
  } = getState();
  const model = [];
  body.forEach((item) => {
    if (item.mappedData.length) {
      item.mappedData.forEach((mapItem) => model.push(mapItem));
    } else {
      const mapItem = {
        externalId: item.externalId,
        restaurantMailbox: item.restaurantMailbox,
        nativeId: targetData._id,
        type: target,
        externalMenuId: item.externalMenuId,
      };
      model.push(mapItem);
    }
  });

  dispatch({ type: SET_PROVIDER_GRUBHUB_LOADING, payload: true });
  return gatewayInstance.put(`doordash-menu/mapping`, model).then((response) => {
    targetData.connectedProviders[PROVIDERS.doordash.toLowerCase()] = true;
    targetData.bulkInfo.isSaved = true;
    if (target === 'item') {
      dispatch(updateProductForm(targetData, true));
    }

    if (target === 'category') {
      dispatch(updateCategory(targetData));
    }
  });
};
